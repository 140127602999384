export const groups ={
  get_list :'view/sessions',
  add :'add/session',
  update:'update/session/',
  view:'find/session/'
}

export const participants ={
    get_list :'view/session-registrations',
    add :'add/session-registration',
    update:'update/session-registration/',
    view:'view/session-registration/',
    delete: 'delete/session-registration',
    find:'find/session/',
    search:'fetch/session-registration',
    view_individual :'view/individual'
  }
