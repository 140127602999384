import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ShaAlertService } from 'src/app/services/alert.service';
import { ApiService } from 'src/app/services/api.service';
import { city, country } from 'src/app/views/on-boarding/onBoardingConfig';
import { organization } from 'src/app/views/organization-management/organizationConfig';
import { participants } from 'src/app/views/registration-management/registration';
import { GlobalFormComponent } from '../global-form';

@Component({
  selector: 'sha-update-alert',
  templateUrl: './update-alert.component.html',
  styleUrls: ['./update-alert.component.scss']
})
export class UpdateAlertComponent extends GlobalFormComponent implements OnInit
{
  Form: FormGroup;
  updateApi: any;
  countries: any[] = [];
  cities: any[] = [];
  image = "view.png";
  buttonLabel = "Update"
  constructor(
    protected fb: FormBuilder,
    protected _route: ActivatedRoute,
    protected router: Router,
    protected apiService: ApiService,
    protected alertService: ShaAlertService,
    private dialogRef: MatDialogRef<UpdateAlertComponent>)
  {
    super(router, _route, alertService, apiService, fb);
    this.getCountries();

  }

  ngOnInit(): void
  {
    this.Form = this.fb.group({
      name: [''],
      email: [''],
      phone: [''],
      city_id: [''],
      country_id: [''],
      gender: [''],
      organization_id: [''],
      event_id: [''],
      session_id: ['']
    })
    this.detailApi = this.updateApi.url;

    this.getDetail();
    this.Form.get('country_id').valueChanges.subscribe((country) =>
    {
      if (country)
      {
        this.apiService.get(city.list + country, true).then(result =>
        {
          this.cities = result?.cities;
        })
        this.Form.get('city_id').enable();
      }
      else
      {
        this.Form.get('city_id').disable();
      }
    });
    super.ngOnInit();

  }
  getCountries()
  {
    this.apiService.get(country.list, true).then(result =>
    {
      this.countries = result?.countries;
    })
  }
  afterDetail(): void
  {
    this.formDetail = this.formDetail.individual;
    let otherDetails = this.formDetail.individuals_detail;
    console.log("update session registration ==>", this.formDetail);
    this.Form.patchValue(otherDetails);
    this.Form.patchValue(this.formDetail);
    // this.Form.patchValue(this.formDetail);
  }
  onSubmit()
  {
    this.Form.get('organization_id').setValue(this.updateApi.data.session_registrations[0].organization_id);
    this.Form.get('event_id').setValue(this.updateApi.data.session_registrations[0].event_id);
    this.Form.get('session_id').setValue(this.updateApi.data.session_registrations[0].id);
    this.apiService.patch(organization.base_slug_api + participants.update + this.updateApi.data.id, this.Form.value).then(response =>
    {
      if (response.statusCode == 200 || response.statusCode == 201)
      {
        this.alertService.alertSuccess("success", "Record updated").then(()=>{
          this.close();
        });
      }
      else if (response?.error)

      {

        this.alertService.alertError("Error", response.error.error.message)

      }
      else
      {
        this.alertService.alertError("error", response.message)
      }
    })
  }
  close(){
    this.dialogRef.close(true);
  }
}
