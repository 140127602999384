<div>

	<div>

		<img [style.height.px]="size.height"
			 *ngIf="onlyImage"
			 [src]="image" />

		<ng-container *ngIf="!onlyImage">
			<div class="my-drop-zone"
				 [style.height.px]="size.height"
				>

				<div class="fileContainer"
					 (mouseover)="onHover('over')"
					 (mouseleave)="onHover('out')"
					 [style.height.px]="size.height">

					<ng-container *ngIf="!image">
						<span *ngIf="!isIconHidden">{{bannerText}}</span>
						<div style="width:100%" *ngIf="!isIconHidden"
							 class="cloud-icon">
							<!-- <mat-icon>cloud_upload</mat-icon> -->
              <img src="assets/images/upload.png" alt="">
			  <div *ngIf="isBackGroundUpload" style="margin-top: 15px;">Upload Background</div>
			  <div *ngIf="!isBackGroundUpload" style="margin-top: 15px;">Upload Image</div>
			  <div *ngIf="!isIconHidden">{{bannerValidation}}</div>
						</div>
						
						<div class="des"
							 *ngIf="isIconHidden">{{description}}</div>
					</ng-container>

					<input type="file"
						   id="avatar"
						   accept="image/png, image/jpeg, image/jpg"
						   (change)="onFileChange($event)"
						   #fileInput />

					<ng-container *ngIf="!onlyImage">
						<div *ngIf="image"
							 class="selected-img">
							<img [style.height.px]="size.height"

								 [src]="image">
							<div *ngIf="isDeleteShown"
								 class="delete"
								 (click)="onDeleteImage()">
								<mat-icon>delete</mat-icon>
							</div>
						</div>
					</ng-container>

				</div>
			</div>
		</ng-container>
		<ng-container *ngIf="excel">
			<input type="file"
				   (change)="onFileChange($event)"
				   #fileInput />
		</ng-container>
	</div>

	<div style="font-weight: bold;">
		{{label}}
	</div>
</div>
