<div class="main-content">
  <div fxLayout="row wrap"
       fxLayoutAlign="space-between center">
    <div class="main-content">
      <p class="page-heading"
         translate="edit_account"></p>
      <p style="width:100%;margin-left: 2%;"
         translate="employee.message"></p>
    </div>
    <img matPrefix src="assets/images/Close.svg"
         alt=""
         routerLink="/user/profile"
         style="cursor: pointer;">
  </div>
  <form [formGroup]="Form">
    <div fxLayout="row wrap">
      <mat-form-field fxFlex="300px"
                      fxFlex.sm="150"
                      fxFlex.xs="100"
                      fxFlexOffset="2">
        <img matPrefix src="assets/images/icons/User.png"
             class="mb-10">
        <mat-label
                   translate="name"></mat-label>
        <input matInput
               type="text"
               fxFlexOffset="1"
               class="mb-7"
               formControlName="name">
      </mat-form-field>
      <mat-form-field fxFlex="300px"
                      fxFlex.sm="150"
                      fxFlex.xs="100"
                      fxFlexOffset="2">
        <img matPrefix src="assets/images/icons/Mail.png"
             class="mb-10">
        <mat-label
                   translate="email"></mat-label>
        <input matInput
               type="text"
               fxFlexOffset="1"
               class="mb-7"
               formControlName="email">
      </mat-form-field>
      <mat-form-field fxFlex="300px"
                      fxFlex.sm="150"
                      fxFlex.xs="100"
                      fxFlexOffset="2">
        <img matPrefix src="assets/images/icons/phone.png"
             class="mb-10">
        <mat-label
                   translate="number"></mat-label>
        <input matInput
               type="text"
               fxFlexOffset="1"
               class="mb-7"
               formControlName="phone">
      </mat-form-field>
      <mat-form-field fxFlex="300px"
                      fxFlex.sm="150"
                      fxFlex.xs="100"
                      fxFlexOffset="2">
        <img matPrefix src="assets/images/icons/User.png"
             class="mb-10">
        <mat-label
                   translate="password"></mat-label>
        <input matInput
               type="text"
               fxFlexOffset="1"
               class="mb-7"
               readonly
               (click)="changePassword()">
      </mat-form-field>
    </div>
    <div class="main-content">
      <button type="button"
              mat-button
              (click)="onSubmit()"
              style="background-color: #305bcf;color: white;"
              class="btn-custom-form"
              translate="update_profile">

      </button>
    </div>
  </form>

</div>
