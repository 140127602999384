<nav class="navbar navbar-expand-lg">
  <button class="navbar-toggler btn btn-outline-primary" type="button" (click)="toggleCollapse()">
    <span class="lnr lnr-menu"></span>
  </button>

  <div class="collapse navbar-collapse justify-content-between"
  [isAnimated]="true"
  [collapse]="isCollapsed">
    <ul class="navbar-nav second-set px-0 d-flex align-items-center">
      <li class="nav-item">
        <a class="btn btn-primary rounded-pill btn-shadow" translate="shared.freeTrail" (click)="goToContactUs()"></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" role="button" translate="shared.aboutShahadh" (click)="goToAboutUs()"></a>
      </li>
      <li class="nav-item" *ngIf="currentRoute != '/'">
        <a class="nav-link" role="button" translate="shared.goToHome" (click)="goToHomePage()"></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" role="button" translate="shared.pkgsAndHowToUse" (click)="goToPricingAndPkgs()"></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" role="button" translate="shared.faqs" (click)="goToFaqs()"></a>
      </li>
    </ul>
    <ul class="navbar-nav first-set px-0 d-flex align-items-center">
      <li class="nav-item">
        <sha-lang-selector></sha-lang-selector>
      </li>
      <li class="nav-item">
        <a class="nav-link" role="button" translate="shared.login" (click)="goToLogin()"></a>
      </li>
      <li class="nav-item">
        <a role="button"
          class="btn btn-light rounded-pill btn-shadow"
          translate="shared.signUp"
          (click)="goToEnrolment()"></a>
      </li>
    </ul>
  </div>
</nav>
