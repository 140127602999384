<mat-card style="box-shadow: none !important;padding: 0 !important;">
  <mat-card-header>
    <mat-card-title>Select Time</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <ngb-timepicker [(ngModel)]="matTime" [meridian]="meridian"></ngb-timepicker>
    <!-- <mat-form-field appearance="outline">
      <mat-label>Time</mat-label>


      <mat-icon matPrefix *ngIf="formControlItem.value && !formControlItem.disabled && !required" (click)="onClear($event)">
        close
      </mat-icon>

      <mat-icon matSuffix *ngIf="!formControlItem.disabled" (click)="openFromIcon(timepicker)">schedule</mat-icon>

    </mat-form-field> -->
    <!-- <ngb-timepicker [(ngModel)]="time"></ngb-timepicker> -->
    <!-- <ngx-mat-timepicker #timepicker format="24"></ngx-mat-timepicker> -->

  </mat-card-content>
  <mat-card-actions>
    <div fxLayout="row wrap"
         fxLayoutAlign="start center"
         fxLayoutGap="10px">

      <div fxFlex>
        <button (click)="onSelectTime(matTime)"
                type="button"
                mat-button
                class="btn-custom-form">
          Confirm
        </button>
      </div>
      <div fxFlex>
        <button type="button"
                (click)="onCancel()"
                mat-button
                style="background-color: #E2AF2A;"
                class="btn-custom-form">
          Cancel
        </button>
      </div>
    </div>
  </mat-card-actions>
</mat-card>
