import { Component, OnInit } from '@angular/core';
import { RoutingBackboneService } from '../services/routing-backbone.service';

@Component({
  selector: 'sha-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private routingBackbone: RoutingBackboneService) { }

  ngOnInit(): void {
  }

  goToAboutUs() {
    this.routingBackbone.goToAboutUsPage();
  }

  goToPrivacyPolicy() {
    this.routingBackbone.goToPrivacyPolicy();
  }

  goToContactUs() {
    this.routingBackbone.goToContactUs();
  }

  goToFaqs() {
    this.routingBackbone.goToFaqs();
  }

  goToPricingAndPkgs() {
    this.routingBackbone.goToPricingAndPkgs();
  }
}
