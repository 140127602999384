import { Component, OnInit } from '@angular/core';
import { Alert } from 'src/app/models/alert';
import { ShaAlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'sha-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class ShaAlertComponent implements OnInit {
  alerts: Alert[];

  constructor(private alertService: ShaAlertService) {}

  ngOnInit(): void {
    this.alertService.getBroadcast.subscribe((alerts) => {
      this.alerts = alerts;
    });
  }
}
