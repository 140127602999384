import { Component, OnInit, ElementRef, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { ShaAlertService } from 'src/app/services/alert.service';

declare let $: any;

@Component({
	selector: 'sha-file-picker',
	templateUrl: './file-picker.component.html',
	styleUrls: ['./file-picker.component.scss']
})
export class FilePickerComponent implements OnInit
{

	@Input() image: any;
	@Input() editType: any = 'add';
	@Input() label;
	@Input() onlyImage:any;
	@Input() imgType:string = "default";
	@Input() excel = false;
	@Input() isBackGroundUpload = false;

	@ViewChild('fileInput', { static: true }) fileInput: ElementRef;

	@Output() onFileSelect: EventEmitter<any> = new EventEmitter;
	@Output() onDeleteFile: EventEmitter<any> = new EventEmitter;
	@Input() description: string;
	@Input() isIconHidden: boolean = false;
	@Input() type: string;
	@Input() bannerText: string;
	@Input() bannerValidation: string;
	@Input() size: { width: number, height: number } = { width: 450, height: 150 };
	@Input() controlName: string = 'image';
	isDeleteShown: boolean = false;
	imageWidth;
	imageHeight;

	constructor(protected alertService: ShaAlertService)
	{
		this.image = '';
	}

	ngOnInit()
	{

	}

	onHover(state: string): void
	{
		if (this.image)
		{
			if (state == 'over')
			{
				this.isDeleteShown = true;
			}
			else
			{
				this.isDeleteShown = false;
			}
		}

	}

	onDeleteImage(): void
	{
		
		if (this.editType == 'add')
		{
			this.image = '';
		}
		else
		{
			this.image = '';
			this.onDeleteFile.emit('this.imageId');
		}

		let dict = {
			valid: true,
			file: null,
			controlName: this.controlName
		}

		this.onFileSelect.emit(dict);
	}

	onFileChange(event)
	{
		let reader: any = new FileReader();
		if (event.target.files && event.target.files.length > 0)
		{

			// Check if file size greater than 1 MB

			let maxfilesize = 1024 * 1024;
			let fileSize = event.target.files[0].size;

			if ( fileSize > maxfilesize ) {
				this.alertService.alertError("Error", 'Maximum file size allowed upto 1MB');
				return;
			}
			// End

			let file = event.target.files[0];
			reader.readAsDataURL(file);
			reader.onload = (event: any) =>
			{
				
				var img: any = new Image();

				img.onload = (e:any) =>
				{
					this.imageWidth = img.width;
					this.imageHeight = img.height;
					let dict = {
						filename: file.name,
						filetype: file.type,
						value: reader.result.split(',')[1],
						valid: false,
						imagePreview: event.target.result,
						file: file,
						controlName: this.controlName,
						width: this.imageWidth,
						height: this.imageHeight,
					}

                   if((this.imgType==='banner'&&this.imageWidth <= 970 && this.imageHeight <= 250)|| (this.imgType==='logo'&&this.imageWidth <= 200 && this.imageHeight <= 200) || (this.imgType==='default')){

					   dict.valid = true;
					   this.onFileSelect.emit(dict);
					    // this.image = event.target.result;
				   }
				   
				   else{
                        this.alertService.alertError("Error", 'Selected image size is not matched');

				   }
				};

				img.src = reader.result;
			};
		}
	}
}
