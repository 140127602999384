import { NgModule } from "@angular/core";
import { FooterComponent } from "./footer/footer.component";
import { TopNavComponent } from "./top-nav/top-nav.component";
import { CollapseModule  } from 'ngx-bootstrap/collapse'
import { AlertModule } from 'ngx-bootstrap/alert';
import { TranslateModule } from "@ngx-translate/core";
import { LayoutManDirective } from './directives/layout-man.directive';
import { LangSelectorComponent } from './lang-selector/lang-selector.component';
import { AccordionComponent } from './accordion/accordion.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CommonModule } from "@angular/common";
import { PhoneNumberComponent } from './phone-number/phone-number.component';
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { AppLoaderComponent } from './app-loader/app-loader.component';
import { ShaAlertComponent } from './alert/alert.component';
import { DisplayNamePipe } from './pipes/display-name.pipe';
import { GlobalListComponent } from "./global-list";
import { MaterialModule } from "../material.module";
import { CardWidgetComponent } from "./card-widget/card-widget.component";
import { ViewAlertComponent } from './view-alert/view-alert.component';
import { UpdateAlertComponent } from './update-alert/update-alert.component';

@NgModule({
declarations: [
    TopNavComponent,
    FooterComponent,
    LayoutManDirective,
    LangSelectorComponent,
    AccordionComponent,
    PhoneNumberComponent,
    AppLoaderComponent,
    ShaAlertComponent,
    DisplayNamePipe,
    GlobalListComponent,
    CardWidgetComponent,
    ViewAlertComponent,
    UpdateAlertComponent
],
exports: [
    TopNavComponent,
    FooterComponent,
    LangSelectorComponent,
    LayoutManDirective,
    AccordionComponent,
    PhoneNumberComponent,
    AppLoaderComponent,
    ShaAlertComponent,
    DisplayNamePipe,
    GlobalListComponent,
    CardWidgetComponent,
    ViewAlertComponent,
    UpdateAlertComponent
],
imports: [
    CollapseModule,
    AlertModule,
    TranslateModule,
    AccordionModule,
    CommonModule,
    NgxIntlTelInputModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule
]})
export class SharedModule {}
