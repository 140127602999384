import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ShaAlertService } from 'src/app/services/alert.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
	selector: 'sha-view-alert',
	templateUrl: './view-alert.component.html',
	styleUrls: ['./view-alert.component.scss']
})
export class ViewAlertComponent implements OnInit
{
	image: any;
	view_details: any;
	deleteApi: any = null;
	updateApi: any;
	config: any;
	type: any;
	return: boolean = false;
	constructor(private apiService: ApiService, private alert: ShaAlertService, private dialogRef: MatDialogRef<ViewAlertComponent>) { }

	ngOnInit(): void
	{
		if (this.view_details.individuals_detail)
		{
			this.view_details['gender'] = this.view_details.individuals_detail.gender;
      this.view_details['country'] = this.view_details.country.name;
      this.view_details['city'] = this.view_details.city.name;
		}

	}
	onEdit()
	{
		this.alert.alertError("error", "there is no design available.")
	}
	onDelete()
	{
		if (this.return == true)
		{
			this.dialogRef.close(true);
		}
		else
		{
			this.apiService.delete(this.deleteApi).then(response =>
			{
				if (response.statusCode == 200)
				{
					this.alert.alertSuccess("success", response.message).then(() =>
					{
						this.dialogRef.close(true);
					});
				}
				else
				{
					this.alert.alertError("error", response.message);
				}
			})
		}
	}
}
