import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { AlertDialog } from 'src/app/core/alert/alert.dialog';
import { Alert } from 'src/app/models/alert';

@Injectable({
  providedIn: 'root'
})
export class ShaAlertService
{
  private alertStack: Alert[];
  private timeout: number;
  private alertBroadcast: BehaviorSubject<Alert[]>;
  type: any;
  constructor(protected dialog: MatDialog)
  {
    this.timeout = 3000;
    this.alertStack = [];
    this.alertBroadcast = new BehaviorSubject([]);
  }

  get getAlerts()
  {
    return this.alertStack;
  }

  get getBroadcast()
  {
    return this.alertBroadcast;
  }

  private queueAlert(alert: Alert, timeout?: number)
  {
    this.alertStack.push(alert);
    this.alertBroadcast.next(this.alertStack);

    setTimeout(() =>
    {
      const findIndex = this.alertStack.indexOf(alert);

      if (findIndex > -1)
      {
        this.alertStack.splice(findIndex, 1);
      }

      this.alertBroadcast.next(this.alertStack);
    }, this.timeout || timeout);
  }

  info(message: string, timeout?: number)
  {
    const alert = new Alert({
      type: 'info',
      message
    });

    this.queueAlert(alert, timeout);
  }

  warning(message: string, timeout?: number)
  {
    const alert = new Alert({
      type: 'warning',
      message
    });
    this.queueAlert(alert, timeout);
  }

  error(message: string, timeout?: number)
  {
    const alert = new Alert({
      type: 'danger',
      message
    });

    this.queueAlert(alert, timeout);
  }

  success(message: string, timeout?: number)
  {
    const alert = new Alert({
      type: 'success',
      message
    });

    this.queueAlert(alert, timeout);
  }


  alertAsk(heading: string, message: string, rightButton: string, leftButton: string, isSideImage?: boolean, imageUrl?: string, hasInput?: boolean)
  {
    var promise = new Promise((resolve, reject) =>
    {
      let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
      dialogRef.componentInstance.alertData = {
        heading: heading,
        message: message,
        hasInput: hasInput,
        isSideImage: isSideImage,
        imageUrl: imageUrl,
        rightButton: {
          text: rightButton,
          class: 'btn-blue'
        },
        leftButton: {
          text: leftButton,
          class: 'btn-white'
        },
        type: 'ask',
      };

      dialogRef.afterClosed().subscribe(result =>
      {
        resolve(result);
      })
    });
    return promise;
  }
  private alertAsk2(heading: string, message: string, rightButton: string, isSideImage: boolean, imageUrl: string, leftButton: string): any
  {
    let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
    dialogRef.componentInstance.alertData = {
      heading: heading,
      message: message,
      hasInput: false,
      isSideImage: isSideImage,
      imageUrl: imageUrl,
      rightButton: {
        text: rightButton,
        class: 'btn-red'
      },
      leftButton: {
        text: leftButton,
        class: 'btn-white'
      },
      type: 'ask',
    };

    dialogRef.afterClosed().subscribe(result =>
    {
      return result;
    })
  }

  public alertError(heading: string, message: string): Promise<any>
  {
    this.type = 'error';
    return this.alert('alert.error', message, 'btn-red');
  }

  public alertSuccess(heading: string, message: string, imageUrl?: any, isSideImage?: boolean, imageType?: any): Promise<any>
  {
    this.type = 'success';
    return this.alert('alert.success', message, 'btn-blue', imageUrl, isSideImage, imageType);
  }

  public alertInfo(heading: string, message: string): Promise<any>
  {
    this.type = 'info';
    return this.alert('alert.info', message, 'btn-white');
  }
  public alertWarn(heading: string, message: string): Promise<any>
  {
    this.type = 'warning';
    return this.alert('alert.warn', message, 'btn-white');
  }

  private alert(heading: string, message: string, btnClass: string, imageUrl?: any, isSideImage?: boolean, imageType?: any): any
  {
    var promise = new Promise((resolve, reject) =>
    {
      let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
      dialogRef.componentInstance.alertData = {
        heading: heading,
        message: message,
        hasInput: false,
        imageUrl: imageUrl,
        isSideImage: false,
        imageType: imageType,
        rightButton: {
          text: '',
          class: 'btn-red'
        },
        leftButton: {
          text: 'alert.button.ok',
          class: btnClass
        },
        type: this.type,
      };

      dialogRef.afterClosed().subscribe(result =>
      {
        resolve(result);
      })
    });
    return promise;
  }
}
