import { Component, Input, OnInit } from '@angular/core';
import { OrgStat } from 'src/app/models/org-stat';
import { config } from 'src/config';

@Component({
  selector: 'sha-stats-sidebar',
  templateUrl: './stats-sidebar.component.html',
  styleUrls: ['./stats-sidebar.component.scss']
})
export class StatsSidebarComponent implements OnInit
{

  orgStats: OrgStat[];
  @Input()
  showStatsBar: boolean;
  @Input() orgStatsData: any;
  ShahadhUser: any;
  logo: any;
  showLogo: boolean = false;
  constructor()
  {
    this.showStatsBar = false;
  }

  ngOnInit(): void
  {
    this.ShahadhUser = JSON.parse(localStorage.getItem('ShahadhUser'));
    this.logo = config.file_url + this.ShahadhUser.user.organization.organization_logo;
    if (this.ShahadhUser.user.organization.organization_logo)
    {
      this.showLogo = true;
    }

    if (this.orgStatsData)
    {
      this.buildOrgStats();
    }
  }

  buildOrgStats()
  {
    this.orgStats = [
      new OrgStat({
        imgUrl: 'assets/images/org/stats-events-bg.png',
        total: this.orgStatsData.total_events,
        title: 'stats.eventsNo'
      }),
      new OrgStat({
        imgUrl: 'assets/images/org/stats-certificates-received.png',
        total: this.orgStatsData.total_purchsed_certificates,
        title: 'stats.certificatesReceived'
      }),
      new OrgStat({
        imgUrl: 'assets/images/org/stats-certificates-issued.png',
        total: this.orgStatsData.total_issued_certificates,
        title: 'stats.certificatesIssued'
      }),
      new OrgStat({
        imgUrl: 'assets/images/org/stats-branch-bg.png',
        total: this.orgStatsData.total_branches,
        title: 'stats.branchNo'
      }),
      new OrgStat({
        imgUrl: 'assets/images/org/stats-events-bg.png',
        total: this.orgStatsData.total_officials,
        title: 'stats.officialNo'
      })
    ];
  }
}
