<sha-top-header [Config]="config"></sha-top-header>
<p class="page-heading main-content"
   translate="my_profile"></p>

<div class="main-content"
     fxLayout="row wrap"
     fxLayoutGap="15px">
  <mat-card class="example-card">
    <mat-card-title-group>
      <img src="assets/images/icons/User.png"
           class="mt-img card-img">
      <mat-label class="fz-label"
                 fxFlex="start"
                 translate="name">
      </mat-label>
    </mat-card-title-group>
    <mat-card-content class="mt-p">
      <p class="card-text font-medium">{{user?.name }}</p>
    </mat-card-content>
  </mat-card>
  <mat-card class="example-card">
    <mat-card-title-group>
      <img src="assets/images/icons/Mail.png"
           class="mt-img card-img">
      <mat-label class="fz-label"
                 fxFlex="start"
                 translate="email"></mat-label>
    </mat-card-title-group>
    <mat-card-content class="mt-p">
      <p class="card-text font-medium">{{user?.email }}</p>
    </mat-card-content>
  </mat-card>
  <mat-card class="example-card">
    <mat-card-title-group>
      <img src="assets/images/icons/phone.png"
           class="mt-img card-img">
      <mat-label class="fz-label"
                 fxFlex="start"
                 translate="col_employee.phone"></mat-label>
    </mat-card-title-group>
    <mat-card-content class="mt-p">
      <p class="card-text font-medium">{{user?.phone }}</p>
    </mat-card-content>
  </mat-card>
  <mat-card class="example-card">
    <mat-card-title-group>
      <img src="assets/images/icons/User.png"
           class="mt-img card-img">
      <mat-label class="fz-label"
                 fxFlex="start"
                 translate="password"></mat-label>
    </mat-card-title-group>
    <mat-card-content class="mt-p">
      <p class="card-text font-medium">{{"******"}}</p>
    </mat-card-content>
  </mat-card>
</div>
