import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { config } from 'src/config';

@Component({
  selector: 'sha-filter-control',
  templateUrl: './filter-control.component.html',
  styleUrls: ['./filter-control.component.scss']
})
export class FilterControlComponent implements OnInit, OnChanges
{
  Form: FormGroup;
  FormTwo: FormGroup;
  @Input() dateRangeArray: any = [];
  @Input() dateArray: any = [];
  @Input() hasSearch: any = false;
  @Input() searchConfig: any = null;
  @Output() finalFilters: EventEmitter<any> = new EventEmitter<any>();
  @Input() filterArray: any = [];
  @Input() searchArray: any = []
  @Input() storage: any = [];
  @Input() builtInFilters: any;
  @Input() hasOnlyDate: any = false;
  @Input() start: Date = null;
  searchTimer: any;
  @Input() layoutAlign: any = "start";
  selectedFilters: any = [];
  valueSelected: string;
  dateLabel = 'registration.search.created_date'
  @Output() selectedItem: EventEmitter<string> = new EventEmitter<string>();
  @Output() filnalFilters: EventEmitter<any> = new EventEmitter<any>();

  constructor(private fb: FormBuilder)
  {
    this.FormTwo = new FormGroup({
      date: new FormControl(this.start),
      start: new FormControl(null),
      end: new FormControl(null)
    });
  }

  ngOnChanges(changes: any): void
  { }

  ngOnInit(): void
  {
    this.Form = this.fb.group({
      search: [''],
    })
    setTimeout(() =>
    {
      this.storage.forEach(element =>
      {
        this.filterArray[element.at].options = JSON.parse(localStorage.getItem(element.get))
      });
    }, 1000);
    setTimeout(() =>
    {
      this.storage.forEach(element =>
      {
        this.searchArray[element.at].options = JSON.parse(localStorage.getItem(element.get))
      });
    }, 1000);
    this.onChangeFilters();

  }
  optionSelected(value)
  {
    this.valueSelected = value;
    this.selectedItem.emit(this.valueSelected);
  }
  onChangeFilters(): void
  {
    this.selectedFilters = [];
    let sort = '', range = '', search = '', searchFilter = '', onlyDate = '', dateFilter = '', dropdownFilters = '', dateRangeFilter = '';

    if (this.builtInFilters)
    {
      this.selectedFilters.push(this.builtInFilters);
    }

    this.filterArray.forEach(element =>
    {
      if (element.selected != 'All')
      {
        if (element.type == 'sort')
        {
          sort = '&sortBy=' + element.key + '&sortOrder=' + element.selected;
        }
        else
        {
          dropdownFilters = dropdownFilters + '&' + element.key + '=' + element.selected
        }
      }
    });
    this.searchArray.forEach(element =>
    {
      if (element.value)
      {
        searchFilter = searchFilter + '&' + element.key + '=' + element.value
      }
    });
    this.dateArray.forEach(element =>
    {
      if (element.value)
      {
        dateFilter = dateFilter + '&' + element.key + '=' + moment(element.value).format('YYYY-MM-DD')
      }
    });
    this.dateRangeArray.forEach(element =>
    {
      if (element.startDate && element.endDate)
      {
        dateRangeFilter = dateRangeFilter + '&' + element.startKey + '=' + moment(element.startDate).format('YYYY-MM-DD') + '&' + element.endKey + '=' + moment(element.endDate).format('YYYY-MM-DD')
      }
    });

    if (this.FormTwo.get('date').value && this.hasOnlyDate)
    {
      let start = moment(this.FormTwo.get('date').value).format(config.filterFormat);
      onlyDate = '&created_date=' + start;
    }

    if (this.searchConfig && this.hasSearch)
    {
      if (this.Form.get('search').value)
      {
        search = '?search=' + this.Form.get('search').value;
      }
    }
    let final = {
      sort: sort,
      search: search,
      filter: dropdownFilters,
      searchFilter: searchFilter,
      date: onlyDate,
      dateFilter: dateFilter,
      dateRangeFilter: dateRangeFilter
    }
    this.finalFilters.emit(final);

  }
  valueChanged(): void
  {
    this.onChangeFilters();
  }
  valueChanged2(): void
  {
    this.onChangeFilters();
  }
  onClear(): void
  {
    this.Form.reset();
    // this.onDateRange.emit('removed');
    this.onChangeFilters();
  }
  onClearDate(key: any)
  {
    for (let i = 0; i < this.dateArray.length; i++)
    {
      if (this.dateArray[i].key == key && this.dateArray[i].value)
      {
        this.dateArray[i].value = null
        this.onChangeFilters();
      }
    }
  }
  onClear2()
  {
    this.FormTwo.reset();
    // this.onDateRange.emit('removed');
    this.onChangeFilters();
  }
  onSearch(): void
  {
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(() =>
    {
      this.onChangeFilters();
    }, 800);

  }
  onClosed(): void
  {
    this.onClear();
  }
  onClearDateRange(start: any, end: any)
  {
    for (let i = 0; i < this.dateRangeArray.length; i++)
    {
      if (this.dateRangeArray[i].start == start && this.dateRangeArray[i].end == end)
      {
        this.dateRangeArray[i] = null
        this.onChangeFilters();
      }
    }
  }
}
