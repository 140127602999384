import { OnInit, Component } from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";

// import { MainService } from "../services/main.service";

export class AlertData
{
  heading: string = '';
  message: string = '';
  hasInput: boolean = false;
  imageUrl?: any = 'success.png';
  imageType?: any;
  isSideImage: boolean = false;
  type: 'ask' | 'success' | 'error' | 'info';
  leftButton: {
    text: string;
    class: string
  };
  rightButton: {
    text: string;
    class: string
  };
}

@Component({
  selector: 'alert-dialog',
  templateUrl: './alert.dialog.html'
})
export class AlertDialog implements OnInit
{
  alertWithImage: boolean = true;
  isLoading: boolean;
  hasInput: boolean = false;
  image = "view.svg";
  reasonTExt = ""
  heading: string = '';
  message: string = '';
  isSideImage: boolean = false;
  type: 'ask' | 'success' | 'error' | 'info';
  cancelButtonText: string;
  submitButtonText: string;
  dataToSubmit: any;
  methodName: any;
  showLoading: boolean;

  alertData: AlertData;

  constructor( // protected mainApiService: MainService,
    protected dialogRef: MatDialogRef<AlertDialog>, protected dialog: MatDialog)
  {
    this.isLoading = false;
    this.showLoading = false;
    // this.hasInput=true;

    this.alertData = {
      heading: 'Data',
      message: '',
      type: 'ask',
      hasInput: false,
      imageUrl: '',
      isSideImage: false,

      imageType: 'top',
      leftButton: {
        text: 'No',
        class: 'Yes'
      },
      rightButton: {
        text: 'No',
        class: 'Yes'
      },
    }
  }

  ngOnInit()
  {
    console.log("alertData == >",this.alertData.type);
    if(this.alertData.type == "success")
    {
      this.alertData.imageUrl = 'alerts/success.png'
     this.alertData.isSideImage = false;
    }
    else{

    // }
    //  (this.alertData.type == "error")
    // {
      this.alertData.imageUrl = 'alerts/error.jpeg'
      this.alertData.isSideImage = false;
    }

  }

  onCancelClick(): void
  {
    this.dialogRef.close(false);
  }

  onSubmitClick(): void
  {
    this.isLoading = true;
    if (this.alertData.hasInput == true)
    {
      let data = this.reasonTExt
      this.dialogRef.close(data);
    }
    else
    {
      this.dialogRef.close(true);
    }
  }
}
