<div class="no-gutters h-100">
	<div class="fluid d-flex flex-column">
		<div class=" no-gutters flex-grow content-wrapper">
			<div class="side-nav-container p-3">
				<sha-side-panel (logoutUrl)="logout($event)"></sha-side-panel>
			</div>
			<div class="main-content main-layout"
				 malihu-scrollbar
				 [scrollbarOptions]="scrollbarOptions">
				<router-outlet></router-outlet>
			</div>
		</div>
	</div>
</div>
