import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ShaAlertService } from 'src/app/services/alert.service';
import { ApiService } from 'src/app/services/api.service';
import { GlobalFormComponent } from 'src/app/shared/global-form';
import { ChangePasswordComponent } from 'src/app/views/organization-management/change-password/change-password.component';
import { organization } from 'src/app/views/organization-management/organizationConfig';

@Component({
  selector: 'sha-edit-user-profile',
  templateUrl: './edit-user-profile.component.html',
  styleUrls: ['./edit-user-profile.component.scss']
})
export class EditUserProfileComponent extends GlobalFormComponent implements OnInit {

  Form: FormGroup;
  constructor(
    protected fb:FormBuilder,
    protected apiService: ApiService,
    protected dialog: MatDialog,
    protected alertService: ShaAlertService,
    protected router:Router,
    protected _route :ActivatedRoute
  ) {
    super(router,_route,alertService,apiService,fb)
   }

  ngOnInit(): void
  {
    this.Form = this.fb.group({
      email:[''],
      name:[''],
      phone:[''],
      // password:['']
    })
    this.type ='Edit';
    this.formApi = 'api/v1/view/user-info';
    this.detailApi = 'api/v1/view/user-info';
    this.getDetail();
    super.ngOnInit();
  }
  changePassword()
  {
    let dialogRef = this.dialog.open(ChangePasswordComponent, { autoFocus: false, width: '400px' });
    dialogRef.componentInstance.email = this.Form.get("email").value;
    dialogRef.componentInstance.url = 'api/v1/update/user-info/'+this.formDetail.id
  }
  afterDetail(){
    this.Form.patchValue(this.formDetail.user);
  }

}
