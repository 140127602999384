import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'sha-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit
{

  @Output() setPage: EventEmitter<any>;
  @Input() perPage: number;
  @Input() totalPages: number;
  @Input() currentPage: number;

  constructor()
  {
    this.setPage = new EventEmitter();
    this.perPage = 20;
    this.totalPages = 0;
    this.currentPage = 1;
  }

  ngOnInit()
  {
    console.log("currentPage",this.currentPage);

  }

  makePagination(page): void
  {
    let dict = { page: page, perPage: this.perPage };

    this.setPage.emit(dict);
  }

  onChangePerPage(): void
  {

    let dict = { page: 1, perPage: this.perPage };
    this.setPage.emit(dict);
  }


}
