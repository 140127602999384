<div class="h-100"
	 malihu-scrollbar
	 [scrollbarOptions]="scrollbarOptions">
	<div class="col-12 fluid d-flex flex-column">
		<div class="row no-gutters flex-column">
			<sha-top-nav></sha-top-nav>
		</div>
		<div class="main-content main-layout col-md-12 col-sm-12">
			<router-outlet></router-outlet>
		</div>
		<sha-footer></sha-footer>
	</div>
</div>
