export const sessions ={
  get_list :'view/sessions',
  add :'add/session',
  update:'update/session/',
  find:'find/session/',
  get_certificates:'view/certificates-available',
  session_registration:'view/session-registrations'
}
export const certificate = {
  view:'view/certificates',
  fetch:'view/sessions',
  find:'view/certificate?individual_id=',
  findById:'view/certificate/',
  resend:'resend/certificate',
  add:'add/certificate',
  templates:'view/certificates-templates',
  feedback:'add/feedback',
  delete:'delete/certificate-template/'
}
