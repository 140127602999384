import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { TranslateLoaderModule } from './translate-loader/translate-loader.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoaderInterceptor } from './shared/interceptors/loader.interceptor';
import { CoreModule } from './core/core.module';
import { MainLayoutComponent } from './template/main-layout.component';
import { MaterialModule } from './material.module';
import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';
import { SimpleLayoutComponent } from './template/simple-layout/simple-layout.component';
import { ApiService } from './services/api.service';
import { LoaderService } from './services/loader.service';
import { LocationStrategy, PathLocationStrategy, APP_BASE_HREF, HashLocationStrategy } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ViewsGuard } from './guards/views.guard';
import { BranchSelectionComponent } from './common/branch-selection/branch-selection.component';
import { UserProfileComponent } from './common/user-profile/user-profile.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { EditUserProfileComponent } from './common/user-profile/edit-user-profile/edit-user-profile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    SimpleLayoutComponent,
    BranchSelectionComponent,
    UserProfileComponent,
    EditUserProfileComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TranslateLoaderModule,
    TranslateModule,
    SharedModule,
    CoreModule,
    MaterialModule,
    MalihuScrollbarModule.forRoot(),
    NgbModule,
    FlexLayoutModule,
    ReactiveFormsModule,FormsModule
  ],
  providers: [
    ViewsGuard,
    ApiService,
    LoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    // { provide: LocationStrategy, useClass: PathLocationStrategy },
    // { provide: APP_BASE_HREF, useValue: '/' },
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
