<form [formGroup]="Form">
  <h5 class="password-header" translate="org.updatePassword"></h5>
  <div class="row">
    <mat-form-field class="col-md-12 form-group">
      <span matPrefix>
        <img src="assets/images/icons/password.svg" alt=""
      /></span>
      <mat-label translate="org.oldPassword"></mat-label>
      <input
        matInput
        type="text"
        [type]="hideOld ? 'password' : 'text'"
        formControlName="old_password"
      />
      <mat-icon matSuffix (click)="hideOld = !hideOld">{{
        hideOld ? "visibility_off" : "visibility"
      }}</mat-icon>
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field class="col-md-12 form-group">
      <span matPrefix>
        <img src="assets/images/icons/password.svg" alt=""
      /></span>
      <mat-label translate="org.newPassword"></mat-label>
      <input
        matInput
        type="text"
        [type]="hideNew ? 'password' : 'text'"
        formControlName="new_password"
      />
      <mat-icon matSuffix (click)="hideNew = !hideNew">{{
        hideNew ? "visibility_off" : "visibility"
      }}</mat-icon>
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field class="col-md-12 form-group">
      <span matPrefix>
        <img src="assets/images/icons/password.svg" alt=""
      /></span>
      <mat-label translate="org.retakeNewpssword"></mat-label>
      <input
        matInput
        type="text"
        [type]="hideRetake ? 'password' : 'text'"
        formControlName="retake_new_password"
      />
      <mat-icon matSuffix (click)="hideRetake = !hideRetake">{{
        hideRetake ? "visibility_off" : "visibility"
      }}</mat-icon>
      <mat-hint *ngIf="!isPasswordMatch" style="color: red"
        >* Retake Password not matched</mat-hint
      >
    </mat-form-field>
  </div>
  <button
    class="rounded-pill btn btn-primary"
    style="margin-left: 88px"
    (click)="onChangePassword()"
  >
    {{'org.changePassword'|translate}}
  </button>
</form>
