import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'sha-simple-layout',
	templateUrl: './simple-layout.component.html',
	styleUrls: ['./simple-layout.component.scss']
})
export class SimpleLayoutComponent implements OnInit
{
	@Input()
	showTopNav: boolean;

	@Input()
	showStatsBar: boolean;
	public scrollbarOptions = { axis: 'y', theme: 'minimal-dark', scrollInertia: 10 };

	constructor()
	{
		this.showTopNav = false;
		this.showStatsBar = false;
	}

	ngOnInit(): void
	{
	}

}
