import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BranchSelectionComponent } from './common/branch-selection/branch-selection.component';
import { EditUserProfileComponent } from './common/user-profile/edit-user-profile/edit-user-profile.component';
import { UserProfileComponent } from './common/user-profile/user-profile.component';
import { ViewsGuard } from './guards/views.guard';
import { MainLayoutComponent } from './template/main-layout.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'organization'
  },

  {
    path: '',
    component: MainLayoutComponent,

    children: [
      { path: 'branch', loadChildren: () => import('./views/branch-management/branch-management.module').then(m => m.BranchManagementModule) },
      {
        path: 'organization', loadChildren: () => import('./views/organization-management/organization-management.module').then(m => m.OrganizationManagementModule)
      },
      {
        path: 'employee', loadChildren: () => import('./views/employee-management/employee-management.module').then(m => m.EmployeeManagementModule)
      },
      {
        path: 'events', loadChildren: () => import('./views/event-management/event-management.module').then(m => m.EventManagementModule)
      },
      {
        path: 'groups', loadChildren: () => import('./views/registration-management/registration-management.module').then(m => m.RegistrationManagementModule)
      },
      {
        path: 'supervisor',
        loadChildren: () => import('./views/supervisor-management/supervisor-management.module').then(m => m.SupervisorManagementModule)
      },
      {
        path: 'attendance',
        loadChildren: () => import('./views/attendance-management/attendance-management.module').then(m => m.AttendanceManagementModule)
      },
      {
        path: 'package', loadChildren: () => import('./views/cert-management/cert-management.module').then(m => m.CertManagementModule)
      },
      {
        path: 'certificate',
        loadChildren: () => import('./views/manage-certificates/manage-certificates.module').then(m => m.ManageCertificatesModule)
      },
      {
        path: 'certificate-design',
        loadChildren: () => import('./views/certificate-management/certificate-management.module').then(m => m.CertificateManagementModule)
      },
      {
        path: 'help-and-support',
        loadChildren: () => import('./views/help-and-support/help-and-support.module').then(m => m.HelpAndSupportModule)
      },
      {
        path: 'manage-individual',
        loadChildren: () => import('./views/manage-individuals/manage-individuals.module').then(m => m.ManageIndividualsModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./views/manage-notifications/manage-notifications.module').then(m => m.ManageNotificationsModule)
      },
      {
        path: 'manage-trainee',
        loadChildren: () => import('./views/manage-trainees/manage-trainees.module').then(m => m.ManageTraineesModule)
      },
      {
        path: 'organization-dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },

      {
        path: 'email-template',
        loadChildren: () => import('./views/email-template/email-template.module').then(m => m.EmailTemplateModule)
      },
      {
        path: 'branches',


        children: [
          {
            path: 'select',
            component: BranchSelectionComponent,
          }],canActivate: [ViewsGuard],
      },
      {
        path: 'user',

        canActivate: [ViewsGuard],
        children: [
          {
            path: 'profile',
            component: UserProfileComponent,
          },
          {
            path: 'profile/edit',
            component: EditUserProfileComponent,
          }]
      }
    ],canActivate: [ViewsGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
