<div class="container-fluid px-0">
    <div class="row">
        <div class="col-sm-12">
            <accordion [isAnimated]="true" [closeOthers]="closeOthers">
                <accordion-group *ngFor="let item of items"
                    [panelClass]="'sha-accordion'"
                    (isOpenChange)="onOpenChanged($event)">
                    <div accordion-heading class="d-flex flex-row align-items-center">
                        <span class="lnr text-primary" [ngClass]="{ 'lnr-plus-circle': !isOpen, 'lnr-circle-minus': isOpen }"></span>
                        <span class="mx-4 h5 my-0">{{ item.heading | translate}}</span>
                    </div>
                    <div class="col-sm-12 mx-4 h5 my-0">
                        {{item.description | translate}}
                    </div>
                </accordion-group>
            </accordion>
        </div>
    </div>
</div>