<div class="container-fluid">
		<div class="pre-footer bg-primary">
			<div class="row"
				 style="margin: 0px 50px;">
				<div class="col-md-2 align-items-center">
					<img src="assets/images/footer/Logo.png"
						 alt="logo">
					<!-- <span class=" text-white" style="margin-top: 10px;"><a class="font-light text-white" role="button" translate="shared.footer.belowLogo" ></a></span> -->
				</div>
				<div class="col-md-1 "></div>
				<div class="col-md-2"
        style="margin-top: 5px;">
					<div class="d-flex flex-column text-white">
						<!-- <span class=" mb-4 font-light "><a role="button" translate="shared.footer.menu.about" (click)="goToAboutUs()"></a></span> -->
						<span class=" mb-4 "><a class="font-medium footer-links"
							   role="button"
							   translate="shared.footer.menu.certificates"></a></span>
						<span class=" mb-4 "><a class="font-medium footer-links"
							   role="button"
							   translate="shared.footer.menu.connectWithUs"
							   (click)="goToContactUs()"></a></span>
						<span class=" mb-4 "><a class="font-regular footer-links"
							   role="button"
							   translate="shared.footer.menu.privacyPolicy"
							   (click)="goToPrivacyPolicy()"></a></span>
					</div>
				</div>
				<div class="col-md-2"
					 style="margin-top: 5px;">
					<div class="d-flex flex-column text-white">
						<span class=" mb-4 "><a class="font-medium footer-links"
							   role="button"
							   translate="shared.footer.menu2.pkgsAndUsage"
							   (click)="goToPricingAndPkgs()"></a></span>
						<span class=" mb-4 "><a class="font-medium footer-links"
							   role="button"
							   translate="shared.footer.menu2.faqs"
							   (click)="goToFaqs()"></a></span>

						<span class=" mb-4 "><a class="font-regular footer-links"
							   role="button"
							   translate="shared.footer.menu2.termsAndConditions"
							   (click)="goToPrivacyPolicy()"></a></span>
					</div>
				</div>
				<div class="col-md-1 col-sm-3"></div>
				<div class="col-md-4 col-sm-4 text-white subscribe-container">
					<label translate="shared.footer.subscribe"></label>
					<div class="input-group mb-3">
						<input type="text"
							   class="form-control font-regular"
							   placeholder="{{'shared.footer.subscribe.email' | translate}}">
						<div class="input-group-append">
							<button class="btn btn-warning "
									type="button"
									translate="shared.footer.subscribe.send"></button>
						</div>
					</div>
					<div class="share-icon-group">
						<a class="mx-3">
							<img src="assets/images/footer/linkedin.png">
						</a>
						<a class="mx-3">
							<img src="assets/images/footer/Instagram.png">
						</a>
						<a class="mx-3">
							<img src="assets/images/footer/Twitter.png">
						</a>
						<a class="mx-3">
							<img src="assets/images/footer/whats-app.png">
						</a>
						<a class="mx-3">
							<img src="assets/images/footer/Mail.png">
						</a>
					</div>
				</div>
			</div>
		</div>
		<div class="copy-right">
	<div class="row">
		<div class="col-md-12 footer text-white d-flex justify-content-center align-items-center">
			<div class="supported-browser-images">
				<img class="mx-2"
					 src="assets/images/footer/chrome.png" />
			</div>
			<div class="footer-text">
				<p class="m-0"
				   translate="shared.footer.copyright"></p>
			</div>
		</div>
	</div>
  </div>
</div>
