import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { LoaderTrack } from 'src/app/models/loader-track';

@Injectable({
  providedIn: 'root'
})
export class LoaderPushService {

  private loaderObservable: Subject<LoaderTrack>;

  constructor() {
    this.loaderObservable = new BehaviorSubject(undefined);
  }

  subscribe(subscriber: (track: LoaderTrack) => void) {
    this.loaderObservable.subscribe(subscriber);
  }

  publish(value: LoaderTrack) {
    this.loaderObservable.next(value);
  }
}
