import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { config } from 'src/config';
import { STORED_CURRENT_LANG } from '../models/constants';
import { ApiService } from '../services/api.service';
import { organization } from '../views/organization-management/organizationConfig';

declare global
{
  interface Window { shahadhUser: any; }
}
@Component({
  selector: 'sha-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent
{
  @Input()
  showTopNav: boolean;

  @Input()
  showStatsBar: boolean;
  logoutUrl = organization.base_slug_api + organization.signOut
  public scrollbarOptions = { axis: 'y', theme: 'minimal-dark', scrollInertia: 10 };

  constructor(private apiService: ApiService, @Inject(DOCUMENT) private document: Document, private router: Router)
  {
    this.showTopNav = false;
    this.showStatsBar = false;

    let shahadhUser = JSON.parse(localStorage.getItem('ShahadhUser'));
    // (<any>window).shahadhUser = shahadhUser;
    window.shahadhUser = shahadhUser || {};
  }

  getBranchId(User)
  {
    switch (User?.user?.role)
    {
      case 'organization':
        return User?.user?.organization?.branch?.id;
        break;

      case 'supervisor':
        return localStorage.getItem('branch') ? JSON.parse(localStorage.getItem('branch')).id : User?.user?.organization?.branch?.id;
        break;

      case 'employee':
        return User.user?.employee_detail?.branch_id;
        break;

      default:
        break;
    }
  }

  logout(event: any)
  {
    this.apiService.logout(event).then(res =>
    {
      if (res.statusCode === 200 || res.statusCode === 201)
      {
        const langFromStorage = localStorage.getItem(STORED_CURRENT_LANG);
        localStorage.clear();
        localStorage.setItem(STORED_CURRENT_LANG, langFromStorage);
        let queryParams = '&lang=' + langFromStorage
        this.document.location.href = config.web_url + '?lang ='+ langFromStorage;
        // this.document.location.href = "http://localhost:4200/#/?lang="+ langFromStorage;
      }
    })
  }
}
