import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidePanelComponent } from './side-panel/side-panel.component';
import { TopHeaderComponent } from './top-header/top-header.component';
import { TableComponentComponent } from './table-component/table-component.component';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { FilterControlComponent } from './filter-control/filter-control.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';
import { MoreActionsComponent } from './more-actions/more-actions.component';
import { PaginationComponent } from './pagination/pagination.component';
import { AlertDialog } from './alert/alert.dialog';
import { SaplingAlertComponent } from './alert/sapling-alert/sapling-alert.component';
import { FilePickerComponent } from './file-picker/file-picker.compoent';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TimePicker } from './timepicker/timepicker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StatsSidebarComponent } from './stats-sidebar/stats-sidebar.component';
@NgModule({
  declarations: [
    SidePanelComponent,
    TopHeaderComponent,
    TableComponentComponent,
    FilterControlComponent,
    MoreActionsComponent,
    PaginationComponent,
    AlertDialog,
    SaplingAlertComponent,
    FilePickerComponent,
    TimePicker,
    StatsSidebarComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    FlexLayoutModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    MalihuScrollbarModule.forRoot(),
    NgbModule


  ],
  exports: [
    SidePanelComponent,
    TopHeaderComponent,
    TableComponentComponent,
    FilterControlComponent,
    MoreActionsComponent,
    PaginationComponent,
    AlertDialog,
    SaplingAlertComponent,
    FilePickerComponent,
    TimePicker,
    StatsSidebarComponent
  ]
})
export class CoreModule { }
