import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ShaAlertService } from 'src/app/services/alert.service';
import { ApiService } from 'src/app/services/api.service';
import { organization } from '../organizationConfig';

@Component({
  selector: 'sha-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit
{
  Form: FormGroup;
  hideOld = true;
  hideNew = true;
  hideRetake = true;
  isPasswordMatch = true;
  url: any;
  email: any;
  constructor(
    private apiService: ApiService,
    private fb: FormBuilder,
    private dialog: MatDialogRef<ChangePasswordComponent>,
    private alert: ShaAlertService
  ) { }

  ngOnInit()
  {
    this.Form = this.fb.group({
      new_password: ['', Validators.required],
      old_password: ['', Validators.required],
      retake_new_password: ['', Validators.required],
      email: [this.email],
    });
  }

  matchNewPassword()
  {
    return this.Form.get('new_password').value ===
      this.Form.get('retake_new_password').value
      ? true
      : false;
  }

  async onChangePassword()
  {
    let api_url = 'api/v1/auth/' + organization.change_password;
    if (this.url == null)
    {
      this.url = api_url;
    }
    if (this.Form.valid)
    {
      if (this.matchNewPassword())
      {
        // this.Form.removeControl('retake_new_password');
        this.apiService
          .post(this.url, this.Form.value, false, false, true)
          .then((response) =>
          {
            if (
              response.statusCode == 200 ||
              response.statusCode == 201 ||
              response.statusCode == 202
            )
            {
              this.alert.alertSuccess(
                'Success',
                response.message
              );
              this.close();
              this.Form.reset();
            } else
            {
              this.alert.alertError('Error', response.message).then(() =>
              {
                // this.close();
              });
            }
          });
      } else
      {
        this.isPasswordMatch = false;
      }
    }
  }
  close()
  {
    this.dialog.close();
  }
}
