import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import * as moment from 'moment';
import { ShaAlertService } from '../services/alert.service';
import { ApiService } from '../services/api.service';
import { attendance } from '../views/attendance-management/attendanceConfig';
import { EventConfig } from '../views/event-management/eventConfig';
import { organization } from '../views/organization-management/organizationConfig';

export class TableFiltersOptions
{
  label: string;
  value: any;
  key: string;
}

export class TableFilters
{
  label: string;
  type: string;
  key: string;
  selected: any;
  options: TableFiltersOptions[]
}

export class SearchFilters
{
  label: string;
  value: string;
  key: string;
}
export class DateFilters
{
  label: string;
  value: string;
  key: string;
}
export class DateRangeFilters
{
  label: string;
  // value: string;
  startDate: string;
  endDate: string;
  startKey: string;
  endKey: string;
}
export class ActionButton
{
  label: string;
  buttonRoute: string;
  type?: string;
  icon: string;
  visibility: boolean = true;
  isConditional?: boolean = false;
  condition?: any = null;
}

@Component({
  selector: 'app-global-list',
  template: ``
})
export class GlobalListComponent implements OnInit
{
  filterArray: TableFilters[] = [];
  searchArray: SearchFilters[] = [];
  listApi: string = '';
  filterUrl: any;
  paginationUrl: string = '';
  page: any = 1;
  perPage: any = 10;
  pagination: any;
  deleteItems: any;
  dataItems: any;
  section: string = '';
  deleteApi: string = '';
  updateUrl = '';
  dateArray: DateFilters[] = [];
  dateRangeArray: DateRangeFilters[] = []


  ShahadhUser: any;
  actionButtons: ActionButton[] = [];


  inputData = {
    imageColumn: 'image',
    actionColumn: 'Actions',
    roundedTable: false,
    firstColumn: 'No.',
    lastColumn: 'Action',
    hasSwitch: false,
  }
  constructor(protected router: Router, protected apiService: ApiService, protected alertService: ShaAlertService)
  {
    this.dataItems = [];
    this.filterArray = []
    this.ShahadhUser = JSON.parse(localStorage.getItem('ShahadhUser'));

  }

  ngOnInit()
  {
    // this.getNurseries();
    // this.getList();
  }
  finalFilters(event): void
  {
    let filterUrl = '';

    if (event.filter)
    {
      filterUrl = filterUrl + event.filter;
    }
    else if (event.filter.length == 0)
    {

    }

    if (event.searchFilter)
    {
      filterUrl = filterUrl + event.searchFilter;
    }
    if (event.dateFilter)
    {
      filterUrl = filterUrl + event.dateFilter;
    }
    if (event.sort)
    {
      filterUrl = filterUrl + event.sort;
    }
    if (event.range)
    {
      filterUrl = filterUrl + event.range;
    }
    if (event.search)
    {
      filterUrl = filterUrl + event.search;
    }

    if (event.date)
    {
      filterUrl = filterUrl + event.date;
    }
    if (event.dateRangeFilter)
    {
      filterUrl = filterUrl + event.dateRangeFilter;

    }
    this.getList(filterUrl)
  }
  onPagination(event): void
  {
    this.paginationUrl = '&page=' + event.page;
    this.page = event.page

    this.getList(this.filterUrl);
  }

  getList(filterUrl?: any): void
  {
    let url = this.listApi;
    this.filterUrl = '';

    if (filterUrl)
    {
      url = url + filterUrl;
      this.filterUrl = filterUrl;
      if (filterUrl != "")
      {
        this.paginationUrl = '&page=' + 1;
      }
    }

    if (this.paginationUrl)
    {
      url = url + this.paginationUrl;
    }

    this.apiService.get(url, false).then(result =>
    {
      if (result.statusCode === 200)
      {
        if (result.data.hasOwnProperty('list'))
        {
          this.dataItems = result.data.list;
        }
        else
        {
          this.dataItems = result.data;
        }
        this.pagination = result.data.pagination;
        this.afterListResponse();
      }
      else if (result.statusCode == 202)
      {
        this.dataItems = [];
        this.alertService.alertError(result.statusCode, result.message).then(() =>
        {
          this.router.navigateByUrl('/package')
        });
      }
      else if(result?.error)
      {
        this.dataItems = [];
        this.alertService.alertError(result.statusCode, result.error.message);
      }
      else
      {
        debugger
        this.dataItems = [];
        this.alertService.alertError(result.statusCode, result.message);
      }
    });
  }
  onDelete(row: any, item: any, url: any): void
  {
    let heading = 'Delete '
    let message = 'Are you sure you want to delete '
    let rightButton = 'Delete ';
    let leftButton = 'Cancel';
    let isSideImage = false;
    let imageUrl = 'alerts/delete.png';
    this.alertService.alertAsk(heading, message, rightButton, leftButton, isSideImage, imageUrl, false).then(result =>
    {
      if (result)
      {
        this.apiService.delete(url).then(result =>
        {
          if (result.statusCode == 200 || result.statusCode == 201)
          {
            this.alertService.alertSuccess("Success", "Record Deleted Successfully");
            this.getList();
          }
          else
          {
            this.alertService.alertError(result.statusCode, result.message);
          }
        });
      }
    })
  }

  afterListResponse(): void
  {
  }
  onUpdate(data)
  {

    this.apiService.patch(this.updateUrl, data).then(response =>
    {
      if (response.statusCode == 201 || response.statusCode == 200)
      {
        //   if(response.data.event != undefined)
        //   {
        //     if(response.data.event.archived == "1"){
        //       this.alertService.alertSuccess("Success", "Archived Event Successfully");
        //       }
        //       else if(response.data.event.archived =="0"){
        //         this.alertService.alertSuccess("Success", "Un-Archived Event Successfully");
        //       }
        //   }
        //   else
        //   {
        //   if(response.data.session.archived == "1"){
        // 	this.alertService.alertSuccess("Success", "Archived Session Successfully");
        //   }
        //   else if(response.data.session.archived =="0"){
        //     this.alertService.alertSuccess("Success", "Un-Archived Session Successfully");
        //   }
        // }
        this.alertService.alertSuccess("success", response.message);
        // this.getList(this.filterUrl);
        this.getList();
      }
      else
      {
        this.alertService.alertError(response.statusCode, response.message);
      }
    })
  }
  statusChanged(event, url)
  {
    this.updateUrl = organization.base_slug_api + url;
    this.updateUrl = this.updateUrl + event.id;
    var dataTosend = { status: event.status };
    if (event.status == '0')
    {
      dataTosend.status = '1';
    }
    else
    {
      dataTosend.status = '0';
    }
    this.onUpdate(dataTosend)
  }

}
