import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ShaAlertService } from 'src/app/services/alert.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'sha-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit
{

  user: any;
  config = {
    buttonLabel: "edit_account",
    heading: "my_profile",
    message: "employee.message",
    buttonRoute: "/user/profile/edit",
    class: 'white-button',
    showButton: true
  }
  constructor(private apiService: ApiService, private alertService: ShaAlertService, private _route: Router) { }

  ngOnInit(): void
  {
    // let SuperAdmin = JSON.parse(localStorage.getItem('SuperAdmin'));
    // if (SuperAdmin)
    // {
    // }
    this.getProfile();

  }
  getProfile()
  {
    let url = 'api/v1/view/user-info'
    this.apiService.get(url, false).then(response =>
    {
      if (response.statusCode == 200 || response.statusCode == 201)
      {
        this.user = response.data.user;
      }
      else
      {
        this.alertService.alertError("alert.error", response.message)
      }
    })
  }
}
