import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RoutingBackboneService {

  constructor(private router: Router,@Inject(DOCUMENT) private document: Document) { }

  goToLandingPage() {
    this.router.navigate(['']);
  }

  goToAboutUsPage() {
    this.router.navigate(['/about-us']);
  }

  goToEnrolment() {
    this.router.navigate(['/register']);
  }

  goToContactUs() {
    this.router.navigate(['/contact-us']);
  }

  goToLogin() {
    this.router.navigate(['/login']);
  }

  goToResetPassword() {
    this.router.navigate(['/login/reset-password']);
  }

  goToFaqs() {
    this.router.navigate(['/faqs']);
  }

  goToPricingAndPkgs() {
    this.router.navigate(['/packages-and-pricing']);
  }

  goToPrivacyPolicy() {
    this.router.navigate(['/privacy-policy']);
  }

  goToProfile() {
    this.router.navigate(['/firm']);
  }

  goToCertificates() {
    this.router.navigate(['/profile/manage-certificates'])
  }

  goToUploadCertificate() {
    this.router.navigate(['/profile/upload-certificates']);
  }

  goToCertificateAllUploads() {
    this.router.navigate(['/profile/all-uploads']);
  }

  goToCertificatePreview() {
    this.router.navigate(['/profile/certificates-preview'])
  }
  goToUrl(): void {
    this.document.location.href = 'https://shahadh.xyz';
}
}
