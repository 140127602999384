import { Component, Input, OnInit } from '@angular/core';
import { ShaAccordion } from 'src/app/models/sha-accordion';

@Component({
  selector: 'sha-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements OnInit {

  isOpen: boolean;
  @Input() items: ShaAccordion[];
  @Input() closeOthers: boolean;

  constructor() {
    this.isOpen = false;
    this.closeOthers = false;
  }

  ngOnInit(): void {
  }

  onOpenChanged(isOpen: boolean) {
    this.isOpen = isOpen;
  }
}
