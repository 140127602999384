<!-- <div class="row  flex-reverse-rtl"
     style="height: 350px;min-width: 800px;">
    <div class="col-6" style="background-image: url(assets/images/{{image}});background-repeat: no-repeat;"></div>
    <div class="col-6"
         style="margin: auto;">
        <div style="font-size: 24px;text-align: center;color: #111e6c;">{{alertData.heading}}</div>

        <div [innerHTML]="alertData.message"
             style="font-size: 16px;color: #666666;text-align: center;margin-top: 20px;padding: 0 30px;">
        </div>
        <div fxLayout="row wrap"
             fxLayoutAlign="space-evenly start"
             fxLayoutGap="20px"
             style="text-align: right;margin-top: 20px;">

            <textarea *ngIf="alertData.hasInput == true"
                      [disabled]="isLoading"
                      style="height: 100px; width: 400px;font-size: 20px;"
                      [(ngModel)]="reasonTExt ">
                    </textarea>
        </div>

        <div fxLayout="row wrap"
             fxLayoutAlign="space-evenly start"
             fxLayoutGap="20px"
             style="text-align: center;margin-top: 30px;border-radius: 20px;">
            <button [ngClass]="alertData.leftButton.class"
                    mat-button
                    [disabled]="isLoading"
                    type="button"
                    (click)="onCancelClick()">{{alertData.leftButton.text}}</button>

            <button [ngClass]="alertData.rightButton.class"
                    mat-button
                    style="margin-left: 10px;"
                    *ngIf="alertData.type == 'ask'"
                    [disabled]="isLoading"
                    type="button"
                    (click)="onSubmitClick()">{{alertData.rightButton.text}}</button>
        </div>
    </div>
</div> -->


<!-- upper code with image and proper spacing -->


<!-- <div style="min-width: 350px;max-width: 500px;" *ngIf="!alertWithImage">
	<div style="font-size: 24px;text-align: center;color: #111e6c;">{{alertData.heading}}</div>

	<div [innerHTML]="alertData.message"
		 style="font-size: 16px;color: #666666;text-align: center;margin-top: 20px;padding: 0 30px;">
	</div>
	<div fxLayout="row wrap"
		 fxLayoutAlign="space-evenly start"
		 fxLayoutGap="20px"
		 style="text-align: right;margin-top: 20px;">

		<textarea *ngIf="alertData.hasInput == true"
				  [disabled]="isLoading"
				  style="height: 100px; width: 400px;font-size: 20px;"
				  [(ngModel)]="reasonTExt ">
                    </textarea>
	</div>

	<div fxLayout="row wrap"
		 fxLayoutAlign="space-evenly start"
		 fxLayoutGap="20px"
		 style="text-align: center;margin-top: 30px;border-radius: 20px;">
		<button [ngClass]="alertData.leftButton.class"
				mat-button
				[disabled]="isLoading"
				type="button"
				(click)="onCancelClick()">{{alertData.leftButton.text}}</button>

		<button [ngClass]="alertData.rightButton.class"
				mat-button
				*ngIf="alertData.type == 'ask'"
				[disabled]="isLoading"
				type="button"
				(click)="onSubmitClick()">{{alertData.rightButton.text}}</button>
	</div>
</div> -->
<div class="row  flex-reverse-rtl"
     style="min-width: 350px;max-width: 800px;">
  <ng-container>
    <div [ngClass]="{'col-6': alertData.isSideImage == true}"
         *ngIf="alertData.isSideImage"
         style="background-image: url(assets/images/{{alertData.imageUrl}});background-repeat: no-repeat;"></div>
    <div [ngClass]="{'col-6': alertData.isSideImage == false}"
         style="margin: auto;">
      <img *ngIf="!alertData.isSideImage"
           style="height: 120px;
      width: auto;
      margin: 0 auto;
      display: block;"
           src="assets/images/{{alertData.imageUrl}}"
           alt="">
    </div>

  </ng-container>
  <div [ngClass]="{'col-6': alertData.isSideImage == true}"
       style="margin: auto;min-width: 220px;">
    <div style="font-size: 24px;text-align: center;color: #111e6c;">{{alertData.heading | translate}}</div>

    <div [innerHTML]="alertData.message | translate"
         style="font-size: 16px;color: #666666;text-align: center;margin-top: 20px;padding: 0 30px;">
    </div>
    <div fxLayout="row wrap"
         fxLayoutAlign="space-evenly start"
         fxLayoutGap="20px"
         style="text-align: right;margin-top: 20px;">

      <textarea *ngIf="alertData.hasInput == true"
                [disabled]="isLoading"
                style="height: 100px; width: 400px;font-size: 20px;"
                [(ngModel)]="reasonTExt ">
                    </textarea>
    </div>

    <div fxLayout="row wrap"
         fxLayoutAlign="space-evenly start"
         fxLayoutGap="20px"
         style="text-align: center;margin-top: 30px;border-radius: 20px;">
      <button [ngClass]="alertData.leftButton.class"
              mat-button
              [disabled]="isLoading"
              type="button"
              (click)="onCancelClick()">{{alertData.leftButton.text | translate}}</button>

      <button [ngClass]="alertData.rightButton.class"
              mat-button
              style="margin-left: 10px;"
              *ngIf="alertData.type == 'ask'"
              [disabled]="isLoading"
              type="button"
              (click)="onSubmitClick()">{{alertData.rightButton.text | translate}}</button>
    </div>
  </div>
</div>
