<div class="alert_container" style="border-radius: 5%;">
	<div class="row flex-reverse-rtl">
		<div class="col-md-11"></div>
		<div class="col-md-1">
			<img src="assets/images/alerts/close.PNG"
				 alt=""
				 mat-dialog-close>
		</div>
	</div>
	<div class="row flex-reverse-rtl">
		<div class="col-md-5 ">
			<img style="width: 100%;"
				 src="assets/images/alerts/{{image}}"
				 alt="">
		</div>
		<div class="col-md-7">
			<table *ngIf="image === 'view.png'"
				   class="table table-borderless mt-3">
				<thead>
					<tr>
						<th scope="col"
							translate="attendee.view_col_name"></th>
						<th scope="col"
							translate="attendee.view_col_phone"></th>
						<th scope="col"
							translate="attendee.view_col_email"></th>
					</tr>
				</thead>
				<tr>
					<td>{{view_details?.name}}</td>
					<td>{{view_details?.phone}}</td>
					<td>{{view_details?.email}}</td>
				</tr>
				<thead>
					<tr>
						<th scope="col"
							translate="attendee.view_col_gender"></th>
						<th scope="col"
							translate="attendee.view_col_country"></th>
						<th scope="col"
							translate="attendee.view_col_city"></th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>{{view_details?.gender}}</td>
						<td>{{view_details?.country}}</td>
						<td>{{view_details?.city}}</td>
					</tr>
				</tbody>
				<div class="mt-3">
					<!-- <button type="button"
							class="btn white-button"
							style="min-width: 130px ; height: 45px; background-color: white;box-shadow: 1px 1px 25px 2px #f5eeee;"
							(click)="onEdit()">
						<span>
							<img src="assets/images/icons/edit.png"
								 alt="">
							&nbsp;
							<span translate="attendee.view_alert.edit"></span>
						</span> </button> -->
				</div>

			</table>
			<div *ngIf="image === 'delete.png'" class="mt-5 mb-5">

				<h4 translate="{{config.heading}}"></h4>
				<h6 translate="{{config.message}}"></h6>
				<div class="mt-5">
					<button type="button"
							(click)="onDelete()"
							class="btn white-button"
							style="min-width: 130px ; height: 45px; background-color: #115ACF;box-shadow: 1px 1px 25px 2px #f5eeee;color: white;"
							translate="attendee.delete.yes"></button>
					&nbsp;
					<button type="button"
							class="btn white-button"
							style="min-width: 130px ; height: 45px; background-color: white;box-shadow: 1px 1px 25px 2px #f5eeee;"
							mat-dialog-close
							translate="attendee.delete.cancel"></button>
				</div>
			</div>
		</div>
	</div>
</div>
