<div fxLayout="row wrap"
     [fxLayoutAlign]="layoutAlign"
     fxLayoutGap="20px"
     fxLayoutGap.sm=""
     fxLayoutGap.xs=""
     >
  <ng-container *ngIf="hasSearch">
  </ng-container>
  <ng-container *ngIf="searchArray.length > 0">
    <ng-container *ngFor="let search of searchArray">
      <mat-form-field fxFlex="200px"
      fxFlex.sm="100"
      fxFlex.xs="100">
        <span matPrefix>
          <img src="assets/images/icons/search.png"
               alt=""></span>
        <mat-label translate="{{search.label}}"></mat-label>
        <input matInput
               (keyup)="onSearch()"
               type="text"
               [(ngModel)]="search.value">
      </mat-form-field>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="filterArray.length > 0">
    <ng-container *ngFor="let filter of filterArray">
      <mat-form-field [fxFlex]="fxFlexInSearch"
      fxFlex.sm="100"
      fxFlex.xs="100">
        <span matPrefix>
          <img src="assets/images/icons/filter.png"
               alt=""></span>
        <mat-label translate="{{filter.label}}"></mat-label>
        <mat-select [(ngModel)]="filter.selected"
                    (selectionChange)="onChangeFilters()">
          <mat-option *ngFor="let option of filter.options"
                      [value]="option?.value">
            {{option?.label | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="hasOnlyDate">
    <form [formGroup]="FormTwo">
      <mat-form-field>
        <mat-label translate="{{dateLabel}}"></mat-label>
        <input matInput
               (click)="picker.open()"
               (dateChange)="valueChanged()"
               placeholder="Select date"
               formControlName="date"
               [matDatepicker]="picker">
        <mat-icon style="cursor: pointer;"
                  *ngIf="FormTwo.get('date').value"
                  matSuffix
                  (click)="onClear2()">close</mat-icon>
        <mat-datepicker-toggle matPrefix
                               [for]="picker">
          <mat-icon matDatepickerToggleIcon>
            <img style="padding-bottom: 5px;"
                 src="assets/images/icons/calendar.png"
                 alt="">
          </mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </form>
  </ng-container>
  <ng-container *ngIf="dateRangeArray.length > 0">
    <ng-container *ngFor="let date of dateArray">
      <mat-form-field [fxFlex]="fxFlexInSort"
                      fxFlex.sm="100"
                      fxFlex.xs="100">
        <mat-label *ngIf="date.label">{{date.label | translate}}</mat-label>
        <input matInput
               readonly
               (click)="picker.open()"
               [(ngModel)]="date.value"
               (dateChange)="valueChanged()"
               [min]="minDate"
               [max]="currentDate"
               [matDatepicker]="picker">
        <mat-icon style="cursor: pointer;"
                  *ngIf="date.value"
                  matSuffix
                  (click)="onClearDate(date.key)">close</mat-icon>
        <mat-datepicker-toggle matPrefix
                               [for]="picker">
          <mat-icon matDatepickerToggleIcon>
            <img style="padding-bottom: 5px;"
                 src="assets/images/icons/calendar.png"
                 alt="">
          </mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="dateRangeArray.length > 0">
    <ng-container *ngFor="let dateRange of dateRangeArray">
      <mat-form-field fxFlex="270px"
                      fxFlex.sm="100"
                      fxFlex.xs="100">
        <mat-label>{{dateRange.label |translate}}</mat-label>
        <mat-date-range-input (click)="FormPicker.open()"
                              [rangePicker]="FormPicker">
          <input matStartDate
                 (dateChange)="valueChanged2()"
                 [(ngModel)]="dateRange.startDate">
          <input matEndDate
                 (dateChange)="valueChanged2()"
                 [(ngModel)]="dateRange.endDate">
        </mat-date-range-input>
        <mat-datepicker-toggle matPrefix
                               [for]="FormPicker">
          <mat-icon matDatepickerToggleIcon>
            <img style="padding-bottom: 5px;"
                 src="assets/images/icons/calendar.png"
                 alt="">
          </mat-icon>
        </mat-datepicker-toggle>
        <mat-date-range-picker #FormPicker>
        </mat-date-range-picker>
      </mat-form-field>

    </ng-container>
  </ng-container>
</div>
