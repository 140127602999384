import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ShaAlertService } from 'src/app/services/alert.service';
import { ApiService } from 'src/app/services/api.service';
import { sessions } from 'src/app/views/manage-certificates/certificateManagement';
import { organization } from 'src/app/views/organization-management/organizationConfig';
import { PanelOption } from './panel-option';

@Component({
  selector: 'sha-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.scss']
})
export class SidePanelComponent implements OnInit
{
  public scrollbarOptions = { axis: 'y', theme: 'minimal-dark', scrollInertia: 10 };
  menus: PanelOption[] = [
    { routerLink: '/organization', logo: 'rocket_launch', label: 'sideNav.controlBoard', title: 'control board', visible: true },
    { routerLink: '/organization-dashboard', logo: 'dashboard', label: 'sideNav.dashboard', title: 'dashboard', visible: true },
    { routerLink: '/employee', logo: 'groups', label: 'sideNav.staffManagement', title: 'manage employee', visible: true },
    { routerLink: '/supervisor', logo: 'supervisor_account', label: 'sideNav.supervisorManagement', title: 'manage supervisor', visible: true },
    { routerLink: '/branch', logo: 'apartment', label: 'sideNav.branchManagement', title: 'manage branch', visible: true },
    { routerLink: '/events', logo: 'calendar_month', label: 'sideNav.eventManagement', title: 'manage event', visible: true },
    { routerLink: '/groups', logo: 'layers', label: 'sideNav.registrationManagement', title: 'manage registration', visible: true },
    { routerLink: '/attendance', logo: 'how_to_reg', label: 'sideNav.attendanceManagement', title: 'manage attendance', visible: true },
    { routerLink: '/certificate', logo: 'card_membership', label: 'sideNav.certificateManagement', title: 'manage certificate', visible: true },
    { routerLink: '/certificate-design', logo: 'web_asset', label: 'sideNav.certificateTemplate', title: 'manage certificate templates', visible: true },
    { routerLink: '/package', logo: 'inventory', label: 'sideNav.packageAndCertificate', title: 'package and certificate', visible: true },
    { routerLink: '/manage-individual', logo: 'person', label: 'sideNav.individual', title: 'manage individual', visible: true },
    { routerLink: '/manage-trainee', logo: 'school', label: 'sideNav.traineeManagement', title: 'manage trainee', visible: true },
    { routerLink: '/help-and-support', logo: 'help_center', label: 'sideNav.helpAndSupport', title: 'help and support', visible: true },
    { routerLink: '/notifications', logo: 'notifications_active', label: 'sideNav.manageNotification', title: 'manage notification', visible: true },
    { routerLink: '/email-template', logo: 'mail', label: 'sideNav.email_template', title: 'email template', visible: true },
  ];
  branch_id:any;
  certificates = 0;
  token: any
  showSideBar: boolean = false;
  menus_accesses: any[] = [];
  package: any;
  package_name: any;

  @Output() logoutUrl: EventEmitter<any> = new EventEmitter<any>();
  @Output() showStatsSideBar: EventEmitter<any> = new EventEmitter<any>();

  isCollapsed = true
  ShahadhUser: any = {};
  available_certificate: any;
  userName:any;
  constructor(private router: Router, private apiService: ApiService, private alert: ShaAlertService)
  {
    this.showSideBar = true;
    this.branch_id=JSON.parse(localStorage.getItem('branch_id'));
    if(!this.branch_id)
    {
      this.apiService.branch.subscribe(res=>{
        this.branch_id=res
      })
    }
  }

  ngOnInit(): void
  {
    this.token = localStorage.getItem('token');
    let package_purchased = JSON.parse(localStorage.getItem('package'));
    this.available_certificate = JSON.parse(localStorage.getItem('available_certificate'));
    localStorage.removeItem('available_certificate');
    if (this.available_certificate)
    {
      this.certificates = this.available_certificate;
      this.getAvailableCertificates();
    }
    else
    {
      this.getAvailableCertificates();
    }
    if (package_purchased)
    {
      this.package = package_purchased;
    }
    this.ShahadhUser = JSON.parse(localStorage.getItem('ShahadhUser'));
    this.menus_accesses = this.ShahadhUser.user.menus_accesses;
    if (this.ShahadhUser.user.role == "organization")
    {
      this.menus = this.menus;
    }
    else
    {
      this.getMenus();
    }
    this.getPackages();
  }
  getMenus()
  {
    let array = [];
    this.menus.forEach(menus =>
    {
      this.menus_accesses.forEach(menus_accesses =>
      {
        if (menus.title == menus_accesses.menu.name)
        {
          array.push(menus);
        }
      });
      // });

      this.menus = array;
    })
  }

  getAvailableCertificates()
  {

    let branchId
    branchId = JSON.parse(localStorage.getItem('branch_id'));

    // switch (this.ShahadhUser?.user?.role)
    // {
    //   case 'organization':
    //     branchId = branchId;
    //     break;

    //   case 'supervisor':
    //     branchId = branchId;
    //     break;

    //   case 'employee':
    //     branchId = branchId;
    //     break;

    //   default:
    //     break;
    // }

    this.apiService.get(organization.base_slug_api + sessions.get_certificates + '?branch_id=' + branchId, false).then(response =>
    {
      this.certificates = response?.data?.certificates_available;
    })
  }

  getRechargeCondition()
  {
    if (this.ShahadhUser?.user.role == 'supervisor')
    {
      return localStorage.getItem('branch') ? true : false;
    } else
    {
      return true;
    }

  }

  onMenuClick(items: any)
  {
    let branch_id = JSON.parse(localStorage.getItem('branch_id'));
    if (branch_id)
    {
      this.router.navigateByUrl(items.routerLink);
    }
    else
    {
      this.alert.alertError("Error", "Please select branch").then(() =>
      {
        this.router.navigateByUrl('branches/select')
      })
    }

  }
  getPackages()
  {
    let ShahadhUser = JSON.parse(localStorage.getItem('ShahadhUser'));
    let branch_id = ShahadhUser.user?.organization?.branch?.id;

    if (branch_id != null)
    {
      // + '?branch_id=' + branch_id
      this.apiService.get(organization.base_slug_api + 'view/purchased-package', false).then(response =>
      {
        if (response.data != null)
        {
          localStorage.setItem('package', JSON.stringify(response.data.organization_package));
          this.package = response.data.organization_package;
        }
        else
        {
          localStorage.setItem('package', JSON.stringify(null));
        }

      })
    }
    else
    {
      // this.alert.alertError("error", "Brnach Id is Missing")
      // this.package = {
      //   selected: 'Free',
      //   type: 'None',
      //   price: 'Free',
      //   start_date: 'None',
      //   end_date: 'None'
      // }
      localStorage.setItem('package', JSON.stringify(null));
    }

  }

  logout()
  {
    this.logoutUrl.emit(organization.base_slug_api + organization.signOut);
  }
}
