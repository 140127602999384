import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ar_SA, en_US, STORED_CURRENT_LANG } from '../models/constants';
import { Language } from '../models/language';

@Injectable({
  providedIn: 'root'
})
export class TranslateLanguageService {
  private availableLang: Language[];
  private currentLang: Language;
  private defaultLang: Language;
  private onLangChange: BehaviorSubject<Language>;

  constructor(private translateService: TranslateService) {
    this.availableLang = [
      ar_SA,
      en_US
    ];

    this.defaultLang = this.availableLang[0];
  }

  initialize() {
    const langFromStorage = localStorage.getItem(STORED_CURRENT_LANG);

    const lang = this.availableLang.find(x => x.code == langFromStorage);

    this.currentLang = lang || this.defaultLang;
    this.onLangChange = new BehaviorSubject(this.currentLang);
  }

  setLang(code: string) {
    const lang = this.availableLang.find(x => x.code === code);

    if (lang) {
      this.translateService.use(lang.code);

      this.currentLang = lang;
      localStorage.setItem(STORED_CURRENT_LANG, code);
      this.onLangChange.next(this.currentLang);
    }
  }

  toggleLang() {
    const otherLang = this.availableLang.find(x => x.code !== this.currentLang.code);
    this.setLang(otherLang.code);
    window.location.reload();
  }

  getCurrentLang(): Language {
    return this.currentLang;
  }

  getSupportedLang(): Language[] {
    return this.availableLang;
  }

  getLangChange$(): Observable<Language> {
    return this.onLangChange;
  }
}
