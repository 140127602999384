import { HttpClient } from "@angular/common/http";
import { MultiTranslateHttpLoader } from "ngx-translate-multi-http-loader";
import { TranslateLanguageService } from "./translate-language.service";


/**
 * Https translate loader factory method.
 * @param httpClient http client.
 * @returns multi translate loader.
 */
export function httpTranslateLoaderFactory(httpClient: HttpClient) {
    return new MultiTranslateHttpLoader(httpClient,
        [
            { prefix: "./assets/i18n/profile/", suffix: ".json" },
            { prefix: "./assets/i18n/marketing/", suffix: ".json" },
            { prefix: "./assets/i18n/shared/", suffix: ".json" },
            { prefix: "./assets/i18n/login/", suffix: ".json" },
            { prefix: "./assets/i18n/enrolment/", suffix: ".json" },
            { prefix: "./assets/i18n/organization/", suffix: ".json" },
            { prefix: "./assets/i18n/branchManagement/", suffix: ".json" },
            { prefix: "./assets/i18n/events/", suffix: ".json" },
            { prefix: "./assets/i18n/employeeManagement/", suffix: ".json" },
            { prefix: "./assets/i18n/registration/", suffix: ".json" },
            { prefix: "./assets/i18n/supervisor/", suffix: ".json" },
            { prefix: "./assets/i18n/attendance/", suffix: ".json" },
            { prefix: "./assets/i18n/certificate/", suffix: ".json" },
            { prefix: "./assets/i18n/dashboard/", suffix: ".json" },
            { prefix: "./assets/i18n/alerts/", suffix: ".json" },
            { prefix: "./assets/i18n/validations/", suffix: ".json" }


        ]);
}

/**
 * Translates language setup method.
 * @param languageService language service.
 * @returns resolved current lang result.
 */
export function translateLanguageSetup(languageService: TranslateLanguageService) {
    return () => {
        return new Promise((resolve) => {
            languageService.initialize();
            const currentLang = languageService.getCurrentLang();
            languageService.setLang(currentLang.code);
            resolve(currentLang);
        });
    }
}
