import { Component, OnInit } from '@angular/core';
import { Language } from 'src/app/models/language';
import { TranslateLanguageService } from 'src/app/translate-loader/translate-language.service';

@Component({
  selector: 'sha-lang-selector',
  templateUrl: './lang-selector.component.html',
  styleUrls: ['./lang-selector.component.scss']
})
export class LangSelectorComponent implements OnInit {

  supportedLanguages: Language[];
  currentLanguage: Language;

  constructor(private languageService: TranslateLanguageService) {
  }

  ngOnInit(): void {
    this.currentLanguage = this.languageService.getCurrentLang();
    this.supportedLanguages = this.languageService.getSupportedLang();
  }

  toggleLang(): void {
    this.languageService.toggleLang();
  }
}
