

import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { ShaAlertService } from 'src/app/services/alert.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
    selector: 'sha-table-component',
    templateUrl: './table-component.component.html',
    styleUrls: ['./table-component.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class TableComponentComponent implements OnInit
{
	objectKeys = Object.keys;
	perPage = 10
	inputData: any;
	selectedRow: any = null;

	columnHeader: any;
  @Input() archive_button : boolean = false;
	@Input() props: { ActionButtons: boolean; inputData: any; columnHeader: any; dataSource: any; pagination: any; };
	@Output() selectedItem: EventEmitter<string> = new EventEmitter<string>();
	@Output() status: EventEmitter<string> = new EventEmitter<string>();
	@Output() onRowAction: EventEmitter<any> = new EventEmitter<any>();
	@Output() onPagination: EventEmitter<any> = new EventEmitter<any>();
	@Output() emitColumnAction: EventEmitter<any> = new EventEmitter<any>();
	@Input() hasHeaderCheckBox: any = false;

    selection = new SelectionModel<any>(true, []);


    checkedBoxes = 0;
    checkedOne;
    showImage: boolean = false;
    showActions: boolean = false;
    expandPanel: boolean = false;
    expandButton: boolean = false;
    hasDivs: boolean = false;
    hasTitle: boolean = false;
    makeDiv: boolean = false;
    hasCustomData: boolean = false;
    hasimageDialog = false;
    customData: any;
    divColor: any;
    dicIcon: any;

	firstColumn = "No."
	lastColumn = "Action"
	roundedTable: boolean;
	id: number;
	expand_button = ""
	expanded: any;
	expand_source = ""
	expandedElements: any;
	sendOutput: any;
	dataSource = new MatTableDataSource();
	divIcon: string;
	notApprovedNames = ""
	// star Day Nursery
	hasPreCircle: any;
	preCircleCol: any;
	hasCheckBox: any;
  hasSwitch:string;
	checkBoxCol: "checkbox";
    selectedLang:any;
	headerCheckBoxValue: any = false;
	@Input() actionButton: any = [];
  hovered : any;

    constructor(
        protected router: Router,
        public dialog: MatDialog,
        protected alertService: ShaAlertService,
        protected apiService: ApiService,
        private activatedRoute: ActivatedRoute)
    {
    }

    ngOnChanges()
    {
        this.onMouseOut();
    }

    ngOnInit()
    {
        this.selectedLang =  localStorage.getItem('STORED_CURRENT_LANG'); 
        this.inputData = this.props.inputData;
        this.columnHeader = this.props.columnHeader;
        this.updateInputData();
        this.dataSource = this.props.dataSource;

    }

    updateInputData()
    {
        this.roundedTable = this.inputData?.roundedTable;
        this.hasSwitch = this.inputData?.hasSwitch;
        this.firstColumn = this.inputData?.firstColumn;
        this.lastColumn = this.inputData?.lastColumn;
        this.hasDivs = this.inputData?.hasDivs;
        this.hasTitle = this.inputData?.hasTitle;
    }

    seeElement(element, data)
    {
        this.showActions = element === this.inputData.actionColumn ? true : false;
        this.showImage = element === this.inputData.imageColumn ? true : false

        this.hasPreCircle = element === this.inputData.preCircleCol ? true : false
        this.hasCheckBox = element === this.inputData.checkBoxCol ? true : false
    }


    applyFilter(filterValue: any)
    {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    afterListResponse(data): void
    {
        this.dataSource = new MatTableDataSource(data);
    }

    statusChanged(element)
    {
        this.status.emit(element);
    }

    setPage(pageData: any)
    {
       
        
        this.onPagination.emit(pageData);
    }

	buttonClicked(row, item?: any)
	{
     if (item.type == "output")
		{
			let dict = {
				row: row,
				item: item,
			}
			this.onRowAction.emit(dict);
			return;
		}
    else if (item.type == "delete")
		{
			let dict = {
				row: row,
				item: item,
			}
			this.onRowAction.emit(dict);
			return;
		}
    else if (item.type == "check")
		{
			let dict = {
				row: row,
				item: item,
			}
			this.onRowAction.emit(dict);
			return;
		}
        else if (item.type == "view")
		{
			let dict = {
				row: row,
				item: item,
			}
			this.onRowAction.emit(dict);
			return;
		}
    // console.log("Clicked Row :",row ,'\n' ,"item :",item);

		let url = this.router.url.split('/')[1] + '/' + item.buttonRoute + '/' + row.id     //get first name from url after localhost

        this.router.navigateByUrl(url);


        if (this.inputData === '')
        {
            let dict = {
                row: row,
                item: item,
            }
            this.onRowAction.emit(dict);
        }
        else if (this.inputData)
        {
            this.sendOutput = {
                "row": row,
                "item": item,
            }
            this.selectedItem.emit(this.sendOutput);
        }
    }

    rowActionClicked(row, item?: any)
    {
        let dict = {
            row: row,
            item: item,
        }
        console.log(dict);
        
        this.onRowAction.emit(dict);
    }

    columnClick(row)
    {
        this.emitColumnAction.emit(row);
    }

    getCondition(item, element)
    {       
        if (item.isConditional)
        {
            return element[item.condition.key] == item.condition.value;
        } else
        {
            return true;
        }
    }



    getPaginationText()
    {
        let pagination = 'Total Count : ';
        if (this.props.pagination.page < this.props?.pagination?.pages)
        {
            pagination += this.perPage * this.props?.pagination?.page + "/" + this.props?.pagination?.count;
        }
        else if (this.props?.pagination?.page == this.props?.pagination?.pages)
        {
            pagination += this.props?.pagination?.count + "/" + this.props?.pagination?.count;
        }

        return pagination;
    }

    onMouseOver(row: any)
    {

        this.props.dataSource.filteredData.forEach(element =>
        {
            if (row.id == element.id)
            {
                element['more'] = "assets/images/icons/more_white.svg";

            }
            else
            {
                element['more'] = "assets/images/icons/more_gray.svg";
            }

        });

    }
    onMouseOut()
    {
        this.props.dataSource.filteredData.forEach(element =>
        {
            element['more'] = "assets/images/icons/more_gray.svg"
        })
    }
}



