import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { STORED_CURRENT_LANG } from 'src/app/models/constants';
import {Config} from './config'

@Component({
  selector: 'sha-top-header',
  templateUrl: './top-header.component.html',
  styleUrls: ['./top-header.component.scss']
})
export class TopHeaderComponent implements OnInit {
  @Input()
  showTopNav: boolean;
  @Input () Config : Config ;
  @Input() isButtonAction = false;
  @Output() buttonAction : EventEmitter<any> = new EventEmitter();

  @Input()
  showStatsBar: boolean;
  headerLabel : any ='';
  message : any ='';
  buttonLabel :any = '';
  class : any = "green-button";
  image_placeholder_left:boolean ;
  constructor() {
    this.showTopNav = true;
    this.showStatsBar = false;
  }
  ngOnInit(): void {
    const langFromStorage = localStorage.getItem(STORED_CURRENT_LANG);
    if(langFromStorage == 'en')
    {
      this.image_placeholder_left = true;
    }
    else
    {
      this.image_placeholder_left =false;
    }
    // this.organizationObject.forEach(element => {

      // this.buttonLabel = this.organizationObject.buttonLabel;
      // this.headerLabel = this.organizationObject.headerLabel;
      // this.message = this.organizationObject.message;
    // });
  }

  onButtonAction() {
    if (this.isButtonAction) {
      this.buttonAction.emit();
    }
  }

}
