import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { LoaderService } from '../services/loader.service';
import { config } from 'src/config';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ApiService
{
	token: any = {};
  isPublic:boolean = false;

	headers: HttpHeaders;
	options: any;
	public baseUrl: string;
	branch = new BehaviorSubject(null); 
	constructor(private http: HttpClient, protected loaderService: LoaderService)
	{
		this.token = localStorage.getItem('token');
		this.baseUrl = config.base_url;

		if (this.token)
		{
			this.headers = new HttpHeaders({ 'Authorization': this.token });
		}
		else
		{
			this.headers = new HttpHeaders({ 'Authorization': config.default_auth_key });
		}
		this.options = { headers: this.headers, observe: 'response' };
	}

	getToken(): void
	{
		this.token = localStorage.getItem('token');
		this.baseUrl = config.base_url;

		if (this.token)
		{
			this.headers = new HttpHeaders({ 'Authorization': this.token });
		}
		else
		{
			this.headers = new HttpHeaders({ 'Authorization': config.default_auth_key });
		}
		this.options = { headers: this.headers, observe: 'response' };
	}

	public get(url: string,isPublic:boolean): Promise<any>
	{
    let options ={}

		this.getToken();
		this.loaderService.setLoading(true);
    if(isPublic==true){
      options = {}
    }
    else if(isPublic==false){
      options = this.options
    }

		return this.http.get(this.baseUrl + url,options).toPromise().then((response: any) =>


		 {
      // if (response.statusCode ===200){
      //   return response}
      // else{
			return this.onResponse(response, 'response');
      // }
		}, (reason: any) =>
		{
			return this.onResponse(reason, 'error');
		}).catch(this.handleError);
	}

	public delete(url: string): Promise<any>
	{
		this.getToken();
		return this.http.delete(this.baseUrl + url, this.options).toPromise().then((response: any) =>
		{
			return this.onResponse(response, 'response');
		}, (reason: any) =>
		{
			return this.onResponse(reason, 'error');
		}).catch(this.handleError);
	}

	public deleteBody(url: string, formData: any): Promise<any>
	{
		this.getToken();
		let options = { headers: this.headers, body: formData };

		return this.http.delete(this.baseUrl + url, options).toPromise().then((response: any) =>
		{
			return this.onResponse(response, 'response');
		}, (reason: any) =>
		{
			return this.onResponse(reason, 'error');
		}).catch(this.handleError);
	}

	public post(apiSlug: string, formData: any, hasFile?: boolean , isMultiForm?: boolean,isPublic:boolean = false): Promise<any>
	{
    let options ={}
		this.getToken();
		let data: any = formData;
		if (hasFile)
		{
			data = new FormData();
			for (var key in formData)
			{
				data.append(key, formData[key]);
			}
		}
    if(isPublic==true){
      options = {}
    }
    else if(isPublic==false){
      options = this.options
    }

		return this.http.post(this.baseUrl + apiSlug, data,options).toPromise().then((response: any) =>
		{
			return this.onResponse(response, 'response');
		}, (reason: any) =>
		{
			return this.onResponse(reason, 'error');
		}).catch(this.handleError);
	}

	public put(apiSlug: string, formData: any, hasFile?: boolean): Promise<any>
	{
		this.getToken();
		let data: any = formData;
		if (hasFile)
		{
			data = new FormData();
			for (var key in formData)
			{
				data.append(key, formData[key]);
			}
		}

		return this.http.put(this.baseUrl + apiSlug, data, this.options).toPromise().then((response: any) =>
		{
			return this.onResponse(response, 'response');
		}, (reason: any) =>
		{
			return this.onResponse(reason, 'error');
		}).catch(this.handleError);
	}

	public patch(apiSlug: any, formData: any, hasFile?: boolean): Promise<any>
	{
		this.getToken();
		let data: any = formData;
		if (hasFile)
		{
			data = new FormData();
			for (var key in formData)
			{
				data.append(key, formData[key]);
			}
		}
		return this.http.patch(this.baseUrl + apiSlug, data, this.options).toPromise().then((response: any) =>
		{
			return this.onResponse(response, 'response');
		}, (reason: any) =>
		{
			return this.onResponse(reason, 'error');
		}).catch(this.handleError);
	}

	public logout(url : any): Promise<any>
	{
		this.getToken();
		return this.http.post(this.baseUrl + url,null, this.options).toPromise().then((response: any) =>
		{
			return this.onResponse(response, 'response');
		}, (reason: any) =>
		{
			return this.onResponse(reason, 'error');
		}).catch(this.handleError);
	}


	public auth(url, formData: any): Promise<any>
	{
		return this.http.post(this.baseUrl + url, formData).toPromise().then((response: any) =>
		{
			if (response.data.hasOwnProperty('user'))
			{
				localStorage.setItem('token', JSON.stringify(response.data));
			}
			else
			{
				localStorage.setItem('token', JSON.stringify(response.data));
			}
			// }
			return response;
		}, (reason: any) =>
		{
			// return this.onResponse(reason, 'error');
			setTimeout(() =>
			{
				this.loaderService.setLoading(false);
			}, 800);

			if (reason.error.code === 401)
			{
				localStorage.clear();
				// window.location.reload();
				let res = {
					code: reason.error.code,
					status: "ERROR",
					message: 'Unauthorized user.',
				}
				return res;
			}
			else if (reason.error.code === 403)
			{
				localStorage.clear();

				let res = {
					code: reason.error.code,
					status: "ERROR",
					message: 'Unauthorized user , you can not access the route.',
				}
				return res;
			}
			else if (reason.error.code === 422)
			{
				let res = {
					code: reason.error.code,
					status: "ERROR",
					message: reason.error.data,
				}
				return res;
			}
			else if (reason.error.code === 500)
			{
				let res = {
					code: reason.error.code,
					status: "ERROR",
					message: reason.error.message,
				}
				return res;
			}
			else
			{
				return reason;
			}
		}).catch(this.handleError);
	}

	/**
	 * onResponse
	 */
	public onResponse(response, type)
	{
		setTimeout(() =>
		{
			this.loaderService.setLoading(false);
		}, 800);

		if (type == 'response')
		{
			if (response.status === 403 || response.statusCode === 403)
			{
				localStorage.clear();
				window.location.reload();
			}
			else
			{

				if(response.data)
        {
          return response.data;
        }
        else if(response.body)
        {
          return response.body;
        }
        else
        {
          return response;
        }
			}
		}
		else
		{
			if (response.error.code === 401)
			{
				localStorage.clear();
				window.location.reload();
				let res = {
					code: response.error.code,
					status: "ERROR",
					message: 'Unauthorized user.',
				}
				return res;
			}
			else if (response.error.code === 403)
			{
				localStorage.clear();

				let res = {
					code: response.error.code,
					status: "ERROR",
					message: 'Unauthorized user , you can not access the route.',
				}
				return res;
			}
			else if (response.error.code === 422)
			{
				let res = {
					code: response.error.code,
					status: "ERROR",
					message: response.error.data,
				}
				return res;
			}
			else if (response.error.code === 500)
			{
				let res = {
					code: response.error.code,
					status: "ERROR",
					message: response.error.message,
				}
				return res;
			}
			else
			{
				if (response.statusText == 'Unknown Error' && response.status == 0)
				{
					let res = {
						status: "Warning",
						message: 'Please Check your Internet Connection',
					}
					return res;
				}
				else
				{
					return response;
				}
			}
		}
	}

	public handleError(error: any): Promise<any>
	{
		return error;
	}
}
