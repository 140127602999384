import { Directive, OnInit } from '@angular/core';
import { Language } from 'src/app/models/language';
import { TranslateLanguageService } from 'src/app/translate-loader/translate-language.service';

@Directive({
  selector: '[shaLayoutMan]'
})
export class LayoutManDirective implements OnInit {
  // set current locale to this.
  currentLocale: string;

  // contains list of rtl locales.
  rtlLocales = ['ar'];

  constructor(private translateService: TranslateLanguageService) { }

  ngOnInit(): void {
    if (this.translateService.getLangChange$) {
      this.translateService.getLangChange$().subscribe((lang: Language) => {
        this.changeBrowserReadWriteDirection(lang);
      });
    }
  }

  private isRtlLocale(lang: Language) {
    this.currentLocale = lang.code;
    return this.currentLocale && this.rtlLocales.indexOf(this.currentLocale) > -1;
  }

  private changeBrowserReadWriteDirection(lang: Language) {
    const documentRoot = document.getElementsByTagName('html')[0];

    if(!documentRoot) {
      return;
    }

    if (this.isRtlLocale(lang)) {
        documentRoot.setAttribute('dir', 'rtl');
    } else {
        documentRoot.removeAttribute('dir');
    }
  }
}
