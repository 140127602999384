<sha-top-header *ngIf="showTopNav"
                [Config]="config"></sha-top-header>
<div class="main-content">
  <div fxLayout="row wrap"
       fxLayoutAlign="space around center"
       fxLayoutGap="15px">
    <mat-card class="mt-2 mb-2"
              fxFlex="31"
              *ngFor="let branch of branches"
              style="cursor: pointer;"
              [ngClass]="{'focused-card': branch.clicked == true}">
      <div fxLayout="row"
           fxLayoutAlign="start center"
           fxLayoutGap="15px"
           (click)="branchClicked(branch)">
        <img style="width: 50px;"
             src="assets/images/Group.png"
             alt="">
        <h6 class="mt-3 mr-3">{{branch?.branch?.name}}</h6>
      </div>

    </mat-card>
  </div>
  <div fxLayout="row"
       class="mt-3">
    <button type="button"
            class="btn rounded-pill btn-outline-primary btn-lg-padding"
            translate="{{'Select'}}"
            (click)="selectBranch()">
    </button>
  </div>
</div>
