import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';

import { config } from 'src/config';
import { ShaAlertService } from '../services/alert.service';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-global-form',
  template: ``
})
export class GlobalFormComponent implements OnInit
{

  // currentDate = new Date();

  Form: FormGroup;
  sub: Subscription;
  formApi: string;
  id: any;
  detailApi: string;
  formDetail: any;
  otherForm: any;
  formValueChanged: any;
  type: any;
  title: any;
  onlyImage: boolean = false;
  hasFile: boolean = false;
  isMultiForm: boolean = false;
  nurseries: any[] = [];
  gardeners: any[] = [];
  species: any[] = [];
  // minDate: Date;
  ArtStationUser: any;
  ShahadhUser: any;
  file_url: any = config.file_url;
  successResponse: any;
  constructor(protected router: Router,
    protected _route: ActivatedRoute,
    protected alertService: ShaAlertService,
    protected apiService: ApiService,
    protected formbuilder: FormBuilder)
  {

    this.Form = this.formbuilder.group({});
    this.ShahadhUser = JSON.parse(localStorage.getItem('ShahadhUser'));
    this.Form.valueChanges.subscribe(result =>
    {

    });

  }

  ngOnInit()
  {
    this.sub = this._route.params.subscribe(params =>
    {
      this.id = params['id'];
      this.type = params['type'];
      this.checkFormUrls();
      this.checkType();
    });
  }

  checkFormUrls(): void
  {

  }

  getField(field: any): any
  {
    return this.Form.get(field).invalid;
    // return false;
  }

  getErrorMessage(field: any): any
  {
  }

  checkType()
  {
    if (this.type != "")
    {

      if (this.type === 'View')
      {
        this.title = "View " + this.title;
        this.onlyImage = true;
        this.Form.disable();
      }
      else if (this.type === 'Edit')
      {
        this.onlyImage = false;
        this.title = "Edit " + this.title
      }
      else
      {
        this.onlyImage = false;
        this.title = "Add " + this.title
      }
    }
  }
  onLocationBack(): void
  {
    window.history.back();
  }

  onSubmit(): void
  {
    if (this.Form.invalid)
    {
      let string = '';
      let form = this.Form.controls;
      for (let key in form)
      {
        if (this.Form.get(key).invalid)
        {

        }
      }
      this.alertService.alertError('WARNING', 'alert.required.data');
      return;
    }
    if (this.type == "View")
    {


    }
    else
    {
      let formData = this.Form.value;

      if (this.formValueChanged)
      {
        formData = this.otherForm;
      }

      if (this.Form.value.hasOwnProperty('from_date'))
      {
        var d = new Date(formData['from_date']);
        var timeZoneDifference = (d.getTimezoneOffset() / 60) * -1; //convert to positive value.
        d.setTime(d.getTime() + (timeZoneDifference * 60) * 60 * 1000);
        d.toISOString();
        formData['from_date'] = d;
      }
      if (this.Form.value.hasOwnProperty('to_date'))
      {
        var d = new Date(formData['to_date']);
        var timeZoneDifference = (d.getTimezoneOffset() / 60) * -1; //convert to positive value.
        d.setTime(d.getTime() + (timeZoneDifference * 60) * 60 * 1000);
        d.toISOString();
        formData['to_date'] = d;

      }

      if (this.type == 'Edit')
      {
        this.apiService.patch(this.formApi, formData, this.hasFile).then(response =>
        {
          if (response.statusCode == 201 || response.statusCode == 200)
          {
            this.alertService.alertSuccess(response.statusCode, response.message,).then(result =>
            {
              if (this.isMultiForm == true)
              {
              }
              else
              {
                this.onLocationBack();
              }

            });

            this.afterSuccessfullyAdd();
          }
          else
          {
            this.alertService.alertError(response.status, response.message);
          }
        })
      }
      else
      {
        this.apiService.post(this.formApi, formData, this.hasFile, this.isMultiForm).then(response =>
        {
          if (response.status == 201 || response.status == 200)
          {
            this.successResponse = response;

            this.alertService.alertSuccess(response.status, response.message).then(result =>
            {
              if (this.isMultiForm == true)
              {
              }
              else
              {
                this.onLocationBack();
              }

            });

            this.afterSuccessfullyAdd();
          }
          else
          {
            this.alertService.alertError(response.status, response.message);
          }
        })
      }

    }

  }

  afterSuccessfullyAdd(): void
  {

  }

  getDetail(): void
  {
    this.apiService.get(this.detailApi, false).then(result =>
    {
      if (result.statusCode === 200 && result.data)
      {
        this.formDetail = result.data;
        if (this.formDetail.length > 0)
        {
          this.formDetail = this.formDetail[0];
        }
        this.Form.patchValue(this.formDetail);
        this.afterDetail();

      }
      else
      {
        this.formDetail = {};
        this.alertService.alertError(result.statusCode, result.message);
      }
    });
  }

  afterDetail(): void
  {

  }

  getImage(item): any
  {
    if (this.formDetail?.organization)
    {
      let file = this.formDetail.organization[item];
      if (file)
        return this.file_url + file;
      else
        return '';
    }
    else if (this.formDetail?.logo_url)
    {
      let file = this.formDetail.logo_url;
      if (file)
        return file;
      else
        return '';
    }
    else
    {
      return '';
    }
  }

  onFileSelect(event)
  {
    if (event.valid)
    {
      this.Form.get(event.controlName).setValue(event.file);
    }
    else
    {
      this.Form.get(event.controlName).setValue(event.file);
      this.alertService.alertError('Image', 'alert.file.invalid')
    }
  }

  onDeleteFile(event): void
  {
    this.Form.get(event.controlName).setValue(null);
  }
  returnFirstWord(title)
  {
    var val;
    val = title.substr(0, title.indexOf(" "));
    if (val == "Edit")
    {
      return "Update";

    }
    else
    {
      return val;
    }
  }
}
