<div class="container-fluid">
  <div class="row">
    <div class="col-sm-11 profile-container">
      <div *ngIf="!showLogo"
           class="profile-picture rounded-circle mt-2"></div>
           <div *ngIf="showLogo" class="upload">
            <img style="border-radius: 50%; border: 3px solid #bdc4cb; width: 100px; height: 100px; margin-left: 5px; position: relative; object-fit: cover;"
                 [src]="logo"
                 alt="">
          </div>
      <div class="profile-details mt-2 text-center">
        <h6>{{ShahadhUser?.user?.name}}</h6>
        <h6>{{ShahadhUser?.user?.role}}</h6>
        <!-- <a class="text-muted" role="button">
                    <span class="lnr lnr-cog"></span>
                    <span class="mr-2" translate="statsBar.accountManagement"></span>
                </a> -->
      </div>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-sm-12">
      <button type="button"
              class="btn btn-success btn-border-radius shadow-green"
              routerLink="/certificate/select-event"
              translate="statsBar.createCertificate"></button>
    </div>
  </div>
  <div class="row my-4">
    <div class="col-sm-12">
      <hr />
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <h6 translate="statsBar.walletBalance"></h6>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-sm-12 wallet-container">
      <div class="container-fluid">
        <div class="row flex-reverse-rtl">
          <div class="col-sm-8 p-0">
            <h1 class="text-primary m-0">{{orgStatsData?.total_available_certificates}}</h1>
            <p class="small m-0"
               translate="statsBar.currentCertBalance"></p>
            <button class="btn btn-default btn-border-radius bg-white shadow-grey mt-3"
                    translate="statsBar.recharge" routerLink="/package"></button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row my-4">
    <div class="col-sm-12">
      <hr />
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <h6 translate="stats.statistics"></h6>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 stats-container"
         role="button">
      <div class="stats-info"
           *ngFor="let stat of orgStats">
        <div class="stat-img">
          <img src="{{stat.imgUrl}}"
               alt="{{stat.imgUrl}}" />
        </div>
        <div class="stat-details">
          <h6 class="m-0">{{stat.total}}</h6>
          <p class="m-0 text-muted small">{{stat.title | translate}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
