/**
 * Base model to perform initialization.
 * @template T partial type.
 */
export abstract class BaseModel<T> {
    constructor(partial?: Partial<T>) {
        if(partial) {
            Object.assign(this, partial);
        }
    }
}