<div class="container-fluid main-content">
	<div class="row top-bar-container shadow-grey">
		<div class="col-md-9">
			<div class="row">
				<div class="col-md-3 image-placeholder"></div>
				<div class="col-md-8">
					<h2 class="font-rb"
						style="white-space: nowrap;"
						translate="{{Config.heading}}"></h2>
					<p class="mb-0 font-light top-header-message"
					   translate="{{Config.message}}"></p>
				</div>
				<div class="col-md-1"></div>
			</div>

		</div>
		<div class="col-md-3" *ngIf="Config.showButton">
			<div class="card-button mt-4">
				<button type="button"
						class="btn {{Config.class}}"
						style="background-color: white;font-size: 16px;"
						(click)="onButtonAction()"
						[routerLink]="Config.buttonRoute"
						translate="{{Config.buttonLabel}}"></button>
			</div>
		</div>
	</div>
</div>
