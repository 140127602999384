import { Component, OnInit } from '@angular/core';
import { LoaderTrack } from 'src/app/models/loader-track';
import { LoaderPushService } from '../services/loader-push.service';

@Component({
  selector: 'sha-app-loader',
  templateUrl: './app-loader.component.html',
  styleUrls: ['./app-loader.component.scss']
})
export class AppLoaderComponent implements OnInit {

  style: any;
  showProgress: boolean;

  constructor(private loaderPushService: LoaderPushService) { }

  ngOnInit(): void {
    this.loaderPushService.subscribe((value) => {
      this.processLoaderStyles(value);
    });
  }

  private processLoaderStyles(track: LoaderTrack) {
    if(!track) {
      return;
    }

    if(track.requestCount === 0 && track.areEqual()) {
      this.showProgress = false;
      return;
    }

    this.showProgress = true;
    this.style = `width: ${track.getResponsePercent()}%`;

    if(track.getResponsePercent() === 100) {
      setTimeout(() => {
        this.showProgress = false;
        this.style = `width: ${0}%`;
      }, 800);
    }
  }
}
