<form [formGroup]="phoneForm">
    <ngx-intl-tel-input
        [cssClass]="cssClass"
        formControlName="{{fieldName}}"
        [preferredCountries]="[CountryISO.UnitedStates, CountryISO.UnitedKingdom, CountryISO.SaudiArabia, CountryISO.India]"
        [enableAutoCountrySelect]="false"
        [enablePlaceholder]="true"
        [searchCountryFlag]="true"
        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
        [selectFirstCountry]="false"
        [selectedCountryISO]="CountryISO.SaudiArabia"
        [maxLength]="15"
        [phoneValidation]="true"
        [customPlaceholder]="searchCountryPlaceholder"
        [separateDialCode]="showCountryCode"
        [inputId]="inputId"
        [attr.name]="fieldName">
    </ngx-intl-tel-input>
    <div class="invalid-feedback" *ngIf="showErrorMsg(fieldName)">Required</div>
</form>
