<div class="row">
  <mat-card *ngFor="let item of view_details" class="col-md-3 view_card">
                   <mat-card-title> <img class="form-icons"
                    src="assets/images/icons/{{item.icon}}"
                    alt=""> &nbsp;{{item.heading | translate}}</mat-card-title>
                <mat-card-content>
                  <p class="card-text font-light">{{item.value}}</p>
                </mat-card-content>
    </mat-card>
</div>

