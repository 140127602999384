import { Component, ComponentFactoryResolver, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';


import { MatDialogRef } from '@angular/material/dialog';
import { formatDate } from '@angular/common';
import { ShaAlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'timepicker-dialog',
  templateUrl: './timepicker.html'
})
export class TimePicker implements OnInit
{
  formControlItem: FormControl = new FormControl("");
  required: boolean = !1;
  @ViewChild("timepicker") timepicker: any;

  disabled = false;
  showSpinners = true;
  stepHour = 1;
  stepMinute = 1;
  stepSecond = 1;
  showSeconds = false;
  enableMeridian = true;
  start_time: any;
  clickedDate: any;
  meridian = true;
  time: any;
  matTime = { hour: 0, minute: 0 };
  constructor(protected formbuilder: FormBuilder, protected dialogRef: MatDialogRef<TimePicker>, protected alertService: ShaAlertService)
  {


  }

  ngOnInit(): void
  {
    let t;
    if (this.time)
    {
      t = this.time.split(':');
      this.matTime.hour = parseInt(t[0]);
      this.matTime.minute = parseInt(t[1]);
      console.log("Time:", this.matTime);
    }

  }

  onSelectTime(selectTime: any): void
  {
    let time = this.time;
    let Selectedtime = selectTime.hour + ':' + selectTime.minute;

    this.dialogRef.close(Selectedtime);
  }

  onCancel(): void
  {
    let dict = {
      selected: false,
      time: 0
    }
    this.dialogRef.close(dict);
  }
  openFromIcon(timepicker: { open: () => void })
  {

    if (!this.formControlItem.disabled)
    {
      timepicker.open();
    }
    this.formControlItem.valueChanges.subscribe(value =>
    {
      console.log("value", value)
    })

  }

  /**
   * Function to clear FormControl's value, called from the HTML template using the clear button
   *
   * @param $event - The Event's data object
   */
  onClear($event: Event)
  {
    this.formControlItem.setValue(null);
  }
}
function TrimColon(time: any)
{
  throw new Error('Function not implemented.');
}

