import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';

@Component({
  selector: 'sha-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss']
})
export class PhoneNumberComponent implements OnInit {

  @Input()
  inputId: string;

  @Input()
  cssClass: string;

  @Input()
  fieldName: string;

  @Input()
  showCountryCode: boolean;

  @Input()
  searchCountryPlaceholder: string;

  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  phoneForm: FormGroup;

  constructor(private formBuilder: FormBuilder) {
    this.cssClass = 'w-100 form-control';
    this.showCountryCode = true;
  }

  ngOnInit(): void {
    const group = {};
    group[this.fieldName] = new FormControl(undefined, [Validators.required]);

    this.phoneForm = this.formBuilder.group(group);
  }

  validate() {
    this.phoneForm.markAllAsTouched();

    if(this.phoneForm.invalid) {
      return false;
    }

    return true;
  }

  showErrorMsg(formControlName: string) {
    const formControl = this.phoneForm.get(formControlName);
    return formControl.touched && formControl.invalid;
  }
}
