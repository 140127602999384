<div class="table-footer">
  <ul class="up-pagination">
    <li>
      <a class="paginate_button previous"
         (click)="$event.target.classList.contains('disabled') || makePagination(currentPage - 1)"
         [ngClass]="{disabled:currentPage === 1}">
        <p style="font-size: 16px;"
           translate="pagination.previous"></p>
      </a>
    </li>
    <li *ngIf="currentPage > 4">
      <span class="pull-left"
            style="display: block; margin: 0 5px">...</span>
    </li>
    <li *ngIf="currentPage > 3">
      <a class="paginate_button"
         (click)="makePagination(currentPage-3)">{{currentPage -3}}</a>
    </li>
    <li *ngIf="currentPage > 2">
      <a class="paginate_button"
         (click)="makePagination(currentPage-2)">{{currentPage - 2}}</a>
    </li>
    <li *ngIf="currentPage > 1">
      <a class="paginate_button"
         (click)="makePagination(currentPage-1)">{{currentPage - 1}}</a>
    </li>
    <li class="active">
      <a class="paginate_button"
         (click)="makePagination(currentPage)">{{currentPage}}</a>
    </li>
    <li *ngIf="currentPage < totalPages">
      <a class="paginate_button"
         (click)="makePagination(currentPage+1)">{{currentPage + 1}}</a>
    </li>
    <li *ngIf="currentPage + 1 < totalPages">
      <a class="paginate_button"
         (click)="makePagination(currentPage+2)">{{currentPage + 2}}</a>
    </li>
    <li *ngIf="currentPage + 2 < totalPages">
      <a class="paginate_button"
         (click)="makePagination(currentPage+3)">{{currentPage + 3}}</a>
    </li>
    <li *ngIf="currentPage + 3 < totalPages">
      <span class="pull-left"
            style="display: block; margin: 0 5px">...</span>
    </li>
    <li>
      <a class="paginate_button next"
         (click)="$event.target.classList.contains('disabled') || makePagination(currentPage + 1)"
         [ngClass]="{disabled:currentPage == totalPages}">
        <p style="font-size: 16px;"
           translate="pagination.next"></p>
      </a>
    </li>
  </ul>
</div>
