import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Actions } from './more-action';

@Component({
	selector: 'sha-more-actions',
	templateUrl: './more-actions.component.html',
	styleUrls: ['./more-actions.component.scss']
})
export class MoreActionsComponent implements OnInit
{
	actionControl = new FormControl();
	@Input() action: Actions;
	constructor() { }
	ngOnInit(): void
	{
		this.actionControl.valueChanges.subscribe(value =>
		{
		})
	}
}
