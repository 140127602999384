import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { element } from 'protractor';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'sha-branch-selection',
  templateUrl: './branch-selection.component.html',
  styleUrls: ['./branch-selection.component.scss']
})
export class BranchSelectionComponent implements OnInit
{
  @Input() showTopNav: boolean = false;

  @Input() showStatsBar: boolean = false;
  config = {
    buttonLabel: "branch.select",
    heading: "branch.select.heading",
    message: "branch.message",
    buttonRoute: "",
    class: '',
    showButton: false
  }
  branches: any[] = [];
  ShahadhUser: any;
  branch: any;
  constructor(protected router: Router,private apiService:ApiService)
  {
    this.showTopNav = true;
    this.showStatsBar = false;
  }

  ngOnInit(): void
  {
    this.ShahadhUser = JSON.parse(localStorage.getItem('ShahadhUser'));
    this.branches = this.ShahadhUser.user.supervisor_branches;
    console.log(this.branches);

  }
  branchClicked(branch: any)
  {
    console.log(branch);
    this.branch = branch;
    this.branches.forEach(element =>
    {
      if (element.branch_id == branch.branch.id)
      {
        element['clicked'] = true;
      }
      else
      {
        element['clicked'] = false;
      }
    })
  }
  selectBranch()
  {
  
    // Setting selelcted branch in User data object
    localStorage.setItem('branch', JSON.stringify(this.branch.branch));
    let userData = JSON.parse(localStorage.getItem('ShahadhUser'));
    userData.user['selectedBranch'] = this.branch.branch;
    localStorage.setItem('ShahadhUser', JSON.stringify(userData));
    localStorage.setItem('branch_id', JSON.stringify(this.branch.branch.id));
    // End
this.apiService.branch.next(this.branch.branch.id)
    this.router.navigate(['user/profile']);

  }
}
