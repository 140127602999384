<div class="alert_container"
     style="border-radius: 5%;">
  <div class="row flex-reverse-rtl">
    <div class="col-md-11"></div>
    <div class="col-md-1">
      <img src="assets/images/alerts/close.PNG"
           alt=""
           mat-dialog-close>
    </div>
  </div>
  <form [formGroup]="Form">
    <div class="row mt-3 flex-reverse-rtl p-2">
      <div class="col-md-5">
        <div>
          <img style="width: 100%;"
               src="assets/images/alerts/view.png"
               alt="">
        </div>
      </div>
      <div class="col-md-7">
        <div class="row">
          <mat-form-field class="col-md-6 form-group">
            <span matPrefix>
              <img src="assets/images/icons/User.png"
                   alt=""></span>
            <mat-label translate="name"></mat-label>
            <input matInput
                   type="text"
                   formControlName="name">
          </mat-form-field>
          <mat-form-field class="col-md-6 form-group">
            <span matPrefix>
              <img src="assets/images/icons/gender.svg"
                   alt=""></span>
            <mat-label translate="gender"></mat-label>
            <mat-select formControlName="gender">
              <mat-option value="male">
                <span translate="male"></span>
              </mat-option>
              <mat-option value="female">
                <span translate="female"></span>
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="col-md-6 form-group">
            <span matPrefix>
              <img src="assets/images/icons/phone.png"
                   alt=""></span>
            <mat-label translate="mobile"></mat-label>
            <input matInput
                   type="text"
                   formControlName="phone">
          </mat-form-field>


          <mat-form-field class="col-md-6 form-group">
            <span matPrefix>
              <img src="assets/images/icons/Mail.png"
                   alt=""></span>
            <mat-label translate="email"></mat-label>
            <input matInput
                   type="text"
                   formControlName="email">
          </mat-form-field>
          <mat-form-field class="col-md-6 form-group">
            <span matPrefix>
              <img src="assets/images/icons/Globe.png"
                   alt=""></span>
            <mat-label translate="country.select"></mat-label>
            <mat-select formControlName="country_id">
              <mat-option *ngFor="let country of countries"
                          [value]="country.id">
                {{country.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="col-md-6 form-group">
            <span matPrefix>
              <img src="assets/images/icons/Globe.png"
                   alt=""></span>
            <mat-label translate="city.select"></mat-label>
            <mat-select formControlName="city_id">
              <mat-option *ngFor="let city of cities"
                          [value]="city.id">
                {{city.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div>
            <button type="button"
                    class="btn btn-success"
                    (click)="onSubmit()"
                    translate="{{buttonLabel}}"></button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
