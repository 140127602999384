import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable } from 'rxjs';

import { config } from 'src/config';
// import { individual, profile } from '../individual_portal/individual';
import { STORED_CURRENT_LANG } from '../models/constants';
import { ShaAlertService } from '../services/alert.service';
import { ApiService } from '../services/api.service';
import { TranslateLanguageService } from '../translate-loader/translate-language.service';
import { organization } from '../views/organization-management/organizationConfig';

@Injectable()
export class ViewsGuard implements CanActivate
{

  constructor(private router: Router,
    private apiService: ApiService,
    private _route: ActivatedRoute,
    private tls: TranslateLanguageService,
    private alertService: ShaAlertService,
    @Inject(DOCUMENT) private document: Document)
  {

  }

  // canActivate(next: ActivatedRouteSnapshot,

  //   state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean
  // {
  //   let qParamMap = next.queryParamMap;

  //   if (qParamMap.keys[0])
  //   {
  //     let lang = qParamMap.get('lang');
  //     localStorage.setItem(STORED_CURRENT_LANG, lang);
  //     this.tls.setLang(lang);

  //     let token = qParamMap.get('token');
  //     localStorage.setItem('token', token);
  //     const queryParams = {};

  //     queryParams['token'] = token;

  //     let currentUrl = state.url.split("?");
  //     if(currentUrl[0]== "organization")
  //     {
  //       this.getOrganization();
  //     }

  //     this.router.navigate([currentUrl[0]], {

  //       queryParams: {

  //         'token': null,
  //         'lang': null

  //       }, relativeTo: this._route

  //     });
  //     // this.getOrganization();
  //   }
  //   return true;
  // }

  refresh(): void
  {
    window.location.reload();
  }
  gotToWeb()
  {
    window.open(config.web_url, '_self');
  }
  getOrganization()
  {
    this.apiService.get(organization.base_slug_api + organization.view_organization, false).then(res =>
    {
      let result = res.data.organizations[0];
      localStorage.setItem('ShahadhUser', result.id);
    })
  }
  canActivate(next: ActivatedRouteSnapshot,

    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean
  {


    let qParamMap = next.queryParamMap;


    if (qParamMap.has('token'))
    {
      // debugger;


      let lang = qParamMap.get('lang');
      localStorage.setItem(STORED_CURRENT_LANG, lang);
      this.tls.setLang(lang);

      let token = qParamMap.get('token');
      localStorage.setItem('token', token);


      const queryParams = {};

      queryParams['token'] = token;

      let currentUrl = state.url.split("?");


      this.router.navigate([currentUrl[0]], {

        queryParams: {

          'token': null,
          'lang': null

        }, relativeTo: this._route

      });

    }

    return new Promise(res =>
    {

      let url = "api/v1/view/user-info"
      this.apiService.get(url, false).then(

        (active) =>
        {
          if (active.statusCode == 200 || active.statusCode == 201)
          {
            // debugger;
            localStorage.setItem('ShahadhUser', JSON.stringify(active.data));
            let data = active.data.user
            if (active.data.user.role == "organization")
            {
              localStorage.setItem('branch_id', JSON.stringify(data.organization.branch.id));
            }
            else if (active.data.user.role == "employee")
            {
              localStorage.setItem('branch_id', JSON.stringify(data.employee_detail.branch_id));
              localStorage.setItem('branch', JSON.stringify(data.employee_detail.branch));
            }
            res(true)
          }
          else if (active.error)
          {
            this.alertService.alertError("alert.error", active?.error?.message).then(() =>
            {
              this.document.location.href = config.web_url
            })
            res(false);
          }
          else
          {
            this.alertService.alertError("alert.error", active.message).then(() =>
            {
              this.document.location.href = config.web_url
            })
            res(false);
          }

        },

        (error) =>
        {
          res(false);
        }

      );

    });

  }

}
