import { BaseModel } from "./base";

export class LoaderTrack extends BaseModel<LoaderTrack> {
    requestCount: number;
    responseCount: number;

    areEqual() {
        return this.requestCount === this.responseCount;
    }

    getResponsePercent() {
        return 100 - (((this.requestCount - this.responseCount) / this.requestCount) * 100);
    }
}