<mat-card class="table-class">
  <div class="data-table ">
    <table mat-table
           [dataSource]="props?.dataSource"
           matSort
           multiTemplateDataRows
           class="mat-elevation-z8 tblowRound">
      <ng-container [matColumnDef]="tableData"
                    *ngFor="let tableData of objectKeys(columnHeader)">
        <th mat-header-cell
            *matHeaderCellDef>
          <span style="color: #909EB2;font-size: 16px;"
                translate="{{columnHeader[tableData]}}"></span>
        </th>
        <ng-container *matCellDef="let element; let index">
          {{seeElement(tableData, element)}}
          <td>
            <mat-cell *ngIf="!showImage &&
								  !showActions &&
								  !hasDivs &&
								  !expandPanel &&
								  !hasCustomData &&
								  !hasimageDialog &&

								  !hasPreCircle && tableData
								  != 'actionStatusColumn'">

              <span class="ellipsis"
                    *ngIf="!hasTitle">{{ element[tableData]?.name || element[tableData]}}</span>
              <span class="ellipsis"
                    *ngIf="hasTitle"
                    [title]="element[tableData]?.name || element[tableData]">{{ element[tableData]?.name
                ||
                element[tableData]}}</span>
            </mat-cell>
            <mat-cell *ngIf="tableData == 'actionStatusColumn'">
              <button (click)="columnClick(element)"
                      mat-button>{{element[tableData]}}</button>
            </mat-cell>
            <mat-cell *ngIf="showImage && !hasimageDialog ">
              <img />
            </mat-cell>
            <mat-cell *ngIf="hasimageDialog">
              <img (click)="openDialog(element.contractImage) "
                   src="assets/images/viewImageICon.svg" />
            </mat-cell>
            <mat-cell *ngIf="hasPreCircle">
              <span>{{element[tableData]}}</span>
            </mat-cell>
            <mat-cell style="height: 50px;"
                      [ngStyle]="{'padding-right': (lastColumn=='') ?'0px':'10px'}"
                      *ngIf="showActions">
              <ng-container *ngIf="hasSwitch">
                <!-- <label class="switch">
                                    <input type="checkbox"
                                           (change)="statusChanged(element)"
                                           [(ngModel)]="element.has_switch">
                                    <span class="slider round"></span>
                                </label> -->
                <mat-slide-toggle class="switch"
                                  (change)="statusChanged(element)"
                                  [(ngModel)]="element.has_switch">
                </mat-slide-toggle>
              </ng-container>

              <ng-container *ngIf="inputData?.actionsType == 'seprate'">
                <ng-container *ngFor="let item of props?.ActionButtons">
                  <button type="button"
                          *ngIf="item.icon =='edit.png'"
                          class="btn white-button"
                          (click)="rowActionClicked(element,item)"
                          [disabled]="!item.visibility">
                    <span translate="{{item.label}}"> </span>
                  </button>
                  <button type="button"
                          *ngIf="item.icon =='view.png'"
                          class="btn green-button"
                          (click)="rowActionClicked(element,item)"
                          [disabled]="!item.visibility">
                    <span translate="{{item.label}}"> </span>
                  </button>
                  <button type="button"
                          *ngIf="item.icon =='delete.svg'"
                          class="btn red-button"
                          (click)="rowActionClicked(element,item)"
                          [disabled]="!item.visibility">
                    <span translate="{{item.label}}"> </span>
                  </button>
                  <button type="button"
                  *ngIf="item.icon =='upload.svg'"
                  class="btn green-button"
                  (click)="rowActionClicked(element,item)"
                  [disabled]="!item.visibility">
            <span translate="{{item.label}}"> </span>
          </button>
                  <span *ngIf="item.icon=='visibility'"
                        class="material-icons blue-eye"
                        (click)="rowActionClicked(element,item)">visibility</span>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="inputData?.actionsType != 'seprate'">

                <button mat-icon-button
                        [matMenuTriggerFor]="menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu" xPosition="before">

                  <ng-container *ngFor="let item of props?.ActionButtons">
                    <button style="min-width: 200px;"
                            mat-menu-item
                            *ngIf="getCondition(item,element)"
                            (click)="buttonClicked(element,item)"
                            [disabled]="!item.visibility"
                            value="{{item.buttonRoute}}">
                      <div *ngIf="selectedLang==='ar'">
                        <span translate="{{item.label}}"></span> &nbsp;
                        <span> <img src="assets/images/icons/{{item.icon}}"
                               alt=""> </span>
                      </div>
                      <div *ngIf="selectedLang==='en'">

                        <span> <img src="assets/images/icons/{{item.icon}}"
                               alt=""> </span>
                        &nbsp;
                        <span translate="{{item.label}}"></span>
                      </div>
                    </button>
                  </ng-container>
                </mat-menu>

                <!-- <span style="cursor: pointer;"
									  (click)="mySelect.open()">
									<img src="{{element?.more}}" />
								</span>
								<mat-select #mySelect
											style="visibility:hidden;width: 0">
									<ng-container *ngFor="let item of props?.ActionButtons">
										<mat-option *ngIf="getCondition(item,element)"
													(click)="buttonClicked(element,item)"
													[disabled]="!item.visibility"
													value="{{item.buttonRoute}}"><span> <img
													 src="assets/images/icons/{{item.icon}}"
													 alt=""> </span> &nbsp; <span translate="{{item.label}}"></span>
										</mat-option>
									</ng-container>
								</mat-select> -->
              </ng-container>

            </mat-cell>
          </td>
        </ng-container>
      </ng-container>
      <tr mat-header-row
          *matHeaderRowDef="objectKeys(columnHeader)"
          class="table-row"></tr>
      <tr mat-row
          *matRowDef="let element; columns: objectKeys(columnHeader);"
          class="table-row"
          (mouseover)="onMouseOver(element)"
          (mouseout)="onMouseOut()">
      </tr>
      <tr class="mat-row"
          style="text-align: center;"
          *matNoDataRow>
        <td class="mat-cell"
            colspan="12">No Record Found</td>
      </tr>
    </table>
  </div>
</mat-card>

<sha-pagination class="pagination justify-content-center"
                *ngIf="props?.pagination?.count > 0"
                (setPage)="setPage($event)"
                perPage="10"
                [totalPages]="props?.pagination?.pages"
                [currentPage]="props?.pagination?.page">
</sha-pagination>
