import { Pipe, PipeTransform } from '@angular/core';
import { ar_SA } from 'src/app/models/constants';
import { Language } from 'src/app/models/language';
import { TranslateLanguageService } from 'src/app/translate-loader/translate-language.service';

@Pipe({
  name: 'displayName'
})
export class DisplayNamePipe implements PipeTransform {

  constructor(private translateImportService: TranslateLanguageService) {
  }

  transform(value: unknown, ...args: unknown[]): unknown {
    const lang = this.translateImportService.getCurrentLang();

    if (lang.code === ar_SA.code) {
      return value['nameAr'];
    }
    else {
      return value['nameEn'];
    }
  }
}
