export const userManagment = {
  signup:'signup',
  admin_login :'adminPortal/login',
  viewProfile:'viewProfile',
  updateProfile:'updateProfile',
  resetPassword:'resetPassword',
  forgotPassword:'forgotPassword',
  logout:'logout',
  generateOTP:'generateOTP',
  validateOTP:'validateOTP',
  login_fund_manager:'login',
  login_employer:'login',
  login_employee:'login',
  login_mobile:'login',
  organization_login:'signIn'
}
export const country ={
  list :'api/v1/countries'
}
export const city = {
  list:'api/v1/cities?country_id='
}
