export const organization ={
  signUp:'add/organization',
  signIn:'signIn',
  view_organization:'view/organizations',
  view_organization_details:'view/organization/', // id
  update_organization:'update/organization/', //id
  forgot:'forgot',
  reset:'reset',
  signOut:'auth/signOut',
  base_slug_api:'api/v1/organization/',
  change_password:'reset-password'
}
export const faqs = {
  view:'view/faqs',
  view_attachments:'view/faqs-attachments'
}
export const notification = {
  view:'view/notifications',
  read:'update/read-notification/'
}
export const contact = {
  contact_us:'contact-us',
}
export const branch = {
  add:'add/branch',
  view_details:'view/branch/', //id
  get_list:'view/branches',
  update:'update/branch/', // id
  update_status:'update/branch-status/', //id
}
export const employee =
{
  add:'add/employee',
  view_details:'view/employee/', //id
  get_list:'view/employees',
  update:'update/employee/', //id
  update_status:'update/employee-status/'  //id
}
export const trainee = {
  list: 'view/trainers'
}
export const individual = {
  list: 'view/individuals'
}
