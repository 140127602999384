export const config =
{
  // base_url: 'http://13.126.174.129/shahadh/', // old till 14-03-2022
  // base_url:'http://8.211.22.47/shahadh/',



  default_auth_key: 'andpercent',
  base_url_slug: 'employee/',
  file_url: 'https://api-primecity.veroke.com/uploads/',
  certificate_url: 'https://api-primecity.veroke.com/certificates_templates_images/',
  cmsDateFormat: 'DD/MM/YYYY',
  dateFormat: 'DD-MM-YYYY',
  filterFormat: 'YYYY-MM-DD',
  cmsTimeFormat: 'hh/mm',
  dateTimeNotification: 'DD/MM/YYYY | hh:mm',

  base_url: 'https://api-primecity.veroke.com/',

  cms_url: 'https://cms-staging.shahadh.xyz/#/',
  web_url: 'https://app-primecity.veroke.com/#/',

  // base_url: 'https://api-internal.shahadh.xyz/',

  // cms_url: 'https://cms-internal.shahadh.xyz/#/',
  // web_url: 'https://web-internal.shahadh.xyz/#/',

  // cms_url:'http://8.211.22.47/Shahadh_CMS',
  // web_url:'http://8.211.22.47/Shahadh_Web/',
  // web_url:'http://8.211.22.47/Shahadh_Web_tmp',

  // cms_url:'http://13.126.174.129/Shahadh_CMS',
  // web_url:'http://13.126.174.129/Shahadh_Web_tmp'
  // web_url:'http://13.126.174.129/Shahadh_Web/'

  // 'http://13.126.174.129:3000/'
}


