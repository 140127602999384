import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { RoutingBackboneService } from '../services/routing-backbone.service'
@Component({
  selector: 'sha-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent implements OnInit
{

  isCollapsed: boolean;
  currentRoute: string;
  constructor(private routerBackbone: RoutingBackboneService, private router: Router)
  {
    this.isCollapsed = true;
    this.currentRoute = router.url;
  }

  ngOnInit(): void
  {
  }

  toggleCollapse(): void
  {
    this.isCollapsed = !this.isCollapsed;
  }

  goToAboutUs()
  {
    this.routerBackbone.goToAboutUsPage();
  }

  goToEnrolment()
  {
    this.routerBackbone.goToEnrolment();
  }

  goToContactUs()
  {
    this.routerBackbone.goToContactUs();
  }

  goToLogin()
  {
    this.routerBackbone.goToLogin();
  }

  goToFaqs()
  {
    this.routerBackbone.goToFaqs();
  }

  goToPricingAndPkgs()
  {
    this.routerBackbone.goToPricingAndPkgs();
  }
  goToHomePage()
  {
    this.routerBackbone.goToLandingPage();
  }
}
