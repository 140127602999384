import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderPushService } from '../services/loader-push.service';
import { LoaderTrack } from 'src/app/models/loader-track';
import { tap } from 'rxjs/operators';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  private apiRouteRoot = 'api/';

  private loaderTrack = new LoaderTrack({
    requestCount: 0,
    responseCount: 0
  });

  constructor(private loaderPushService: LoaderPushService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if(request.url.indexOf(this.apiRouteRoot) > -1) {
      this.loaderTrack.requestCount++;
      this.loaderPushService.publish(this.loaderTrack);
    }

    return next.handle(request).pipe(
      tap(event => {
        if(event instanceof HttpResponse && event.url.indexOf(this.apiRouteRoot) > -1) {
          this.loaderTrack.responseCount++;
          this.loaderPushService.publish(this.loaderTrack);
        }
      })
    );
  }
}
