<div class="container-fluid alert-container">
    <div class="row">
        <div class="col-sm-12">
            <div class="alert-canvas">
                <alert type="{{alert.type}}" [dismissible]="true" *ngFor="let alert of alerts">
                    {{alert.message | translate}}
                </alert>
            </div>
        </div>
    </div>
</div>
